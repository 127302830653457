import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../../context/language.context";
import { AppContext } from "../../../context/app.context";

import close from "../../../images/CloseIcon.svg";

const customStyleslogin = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "90%",
    padding: "0px",
    maxWidth: "901px",
    transform: "translate(-50%, -50%)",
    borderRadius: "36px",
    border: "none",
  },
};

const FacilitatedWorkshopRescheduleModal = ({
  bookingItem,
  rescheduleCallback,
  purchaseRedirect = false,
  availableFacilitators = false,
}) => {
  const navigate = useNavigate();
  const [appState, setAppState] = useContext(AppContext);
  const [language] = useContext(LanguageContext);
  const usedLanguage = language.translations[language.selectedLanguage];

  const { facilitatedWorkshopRescheduleModalOpen } = appState;

  const { Dash_MB_36, Dash_MB_37, Dash_MB_38, Dash_MB_39 } =
    usedLanguage.Dashboard;

  const closeModal = () => {
    setAppState({ ...appState, facilitatedWorkshopRescheduleModalOpen: false });
  };

  const purchase = () => {
    if (purchaseRedirect) {
      navigate(`/dashboard?lang=${language.selectedLanguage}`);
    }
    setAppState({
      ...appState,
      facilitatedWorkshopRescheduleModalOpen: false,
      openPurchaseLicensesModalForce: true,
    });
  };

  const reschedule = () => {
    if (bookingItem.facilitated && !availableFacilitators) {
      if (purchaseRedirect) {
        navigate(`/dashboard?lang=${language.selectedLanguage}`);
      }

      setAppState({
        ...appState,
        facilitatedWorkshopRescheduleModalOpen: false,
        openPurchaseLicensesModalForce: true,
      });

      return;
    }

    setAppState({
      ...appState,
      facilitatedWorkshopRescheduleModalOpen: false,
      openPurchaseLicensesModalForce: false,
    });

    rescheduleCallback(bookingItem);
  };

  return (
    <Modal
      isOpen={facilitatedWorkshopRescheduleModalOpen}
      onRequestClose={closeModal}
      style={customStyleslogin}
      ariaHideApp={false}
      contentLabel="Reschedule facilitated workshop"
    >
      <div className="modal-number">
        <div className="close-modal" style={{ textAlign: "right" }}>
          <img src={close} alt="" onClick={closeModal} />
        </div>
        <h1 className="noofparttextmodel" style={{ textAlign: "center" }}>
          {Dash_MB_36}
        </h1>
        <p className="popuptext" style={{ textAlign: "center" }}>
          {Dash_MB_37}
        </p>
        <div className="purchase_btns">
          <button
            className="yes"
            onClick={() => {
              purchase();
            }}
          >
            {Dash_MB_38}
          </button>
          <button
            className="no"
            onClick={() => {
              reschedule();
            }}
          >
            {Dash_MB_39}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FacilitatedWorkshopRescheduleModal;
