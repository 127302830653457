import React, { useEffect, useContext } from 'react'
import Thankyouimg from "../../images/thankyou.png";
import { LanguageContext } from "../../context/language.context";


const Thankyou = () => {
    const [language] = useContext(LanguageContext);
    const usedLanguage = language.translations[language.selectedLanguage];

    const {
        Dash_AVB_10_THK_1,
        Dash_AVB_10_THK_2,
        Dash_AVB_10_THK_3,
        Dash_AVB_10_THK_4,
        Dash_AVB_10_THK_5,
        Dash_AVB_10_THK_6,
        Dash_AVB_10_THK_7,


    } = usedLanguage.Dashboard;
    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-10'>
                        <span className='thanku'>
                            {Dash_AVB_10_THK_1}
                        </span>
                    </div>
                    <div className='col-lg-2'>
                        <img height='106px' width='106px' src={Thankyouimg} alt="" />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-10'>
                        <span className='thankusub'>
                            {Dash_AVB_10_THK_2}
                        </span>
                    </div>
                    <div className='col-lg-2'>

                    </div>
                </div>
                <div className='row' style={{ marginTop: '40px' }}>
                    <div className='col-lg-10'>
                        <span className='thankusub'>
                            <p>{Dash_AVB_10_THK_3}</p>
                            <p>{Dash_AVB_10_THK_4}</p>
                        </span>
                    </div>
                    <div className='col-lg-2'>

                    </div>
                </div>
                <div className='row' style={{ marginTop: '20px' }}>
                    <div className='col-lg-12'>
                        <span className='thankusub'>
                            {Dash_AVB_10_THK_5}
                        </span>
                    </div>

                </div>
                <div className='row' style={{ marginTop: '20px' }}>
                    <div className='col-lg-12'>
                        <span className='thankusub'>
                            {Dash_AVB_10_THK_6} <a href='mailto:booking@mission-team.com'>booking@mission-team.com</a> {Dash_AVB_10_THK_7} <span className='clickhere'>+41 41 500 04 77</span>.
                        </span>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Thankyou