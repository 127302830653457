import React, { createContext, useState } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = (props) => {
  const [language, setLanguage] = useState({
    selectedLanguage: "en",
    availableLanguages: ["en", "de", "it", "fr"],
    translations: {
      en: {
        LoginPage: {
          Login_h1_1: "Manage my Bookings",
          Login_h1_2: "Mission Team Digital Teambuilding",
          Login_SubTxt:
            "Please enter your personal Customer Identification Code to manage your bookings",
          Login_Code_WtMrk: "Enter the Code",
          Login_Code_BTntxt: "Proceed",
          Login_forgtlnk_1: "Forgot Customer Identification Code?",
          Login_forgtlnk_2: "Click here",
          Login_Frgtbx: "Request new Code",
          Login_Frgtbx_subtxt1:
            "Please enter your email address and we will send you a new code.",
          Login_Frgtbx_subtxt2: "Send Code",
          Login_Frgtbx_subtxt3: "Need more help?",
          Login_Frgtbx_subtxt4: "Your code has been sent!",
          Login_Frgtbx_subtxt5:
            "Your new Customer Identification Code has been sent to you.",
          Login_Frgtbx_subtxt6:
            "If you have not received the email, please check first in your SPAM folder. If you still don’t find the email contact us via",
          Login_Frgtbx_subtxt7: "or call us",
        },
        Dashboard: {
          Dash_MainH1: "Manage existing bookings",
          Dash_Viewall: "View all",
          Dash_Yes: "Yes",
          Dash_No: "No",
          Dash_MB_H1: "upcoming workshops",
          Dash_MB_1: "Workshop Date",
          Dash_MB_2: "Workshop Time",
          Dash_MB_3: "Group Size",
          Dash_MB_3_1: "Facilitated",
          Dash_MB_4: "Team Name",
          Dash_MB_5: "Language",
          Dash_MB_6: "Change Booking",
          Dash_MB_6_1: "Info",
          Dash_MB_7:
            "If you reschedule a facilitated workshop, the facilitator license will be forfeited. However, with regards to the Participants’ licenses you may in any case change the booking and reschedule the workshop any time until 24 hours before the workshop.",
          Dash_MB_8: "Number of participants updated!",
          Dash_MB_9:
            "If you added a participant, please do not forget to send a VIP Access Code as well as the link to the VIP and the link to the workshop to the new participant.",
          Dash_MB_10:
            "If you deleted a participant, you may send the unused VIP Access Code to another participant for this workshop or for a new workshop.",
          Dash_MB_11: "Workshop Deleted!",
          Dash_MB_12: "You have deleted an existing workshop.",
          Dash_MB_13:
            "The number of seats allocated to the deleted workshop has now become available again.You may book a new workshop.",
          Dash_MB_14: "Delete booking?",
          Dash_MB_15: "Are you sure you would like to delete this booking",
          Dash_MB_16: "No keep",
          Dash_MB_17: "Yes delete",
          Dash_MB_18: "Reschedule your workshop date and starting time",
          Dash_MB_19: "Updated date and/or time of workshop!",
          Dash_MB_20:
            "Please note that the two links (for participants and the co-moderator) that you have already received to access the workshop will not work anymore.",
          Dash_MB_21:
            "To assist you, we have sent you again the email with the complete information needed by the participants including the co-moderator. Please note that only the two separate access links for the workshop (one for the co-moderator and one for the participant) are new. The remaining information is the same as for the previously scheduled workshop.",
          Dash_MB_20_F:
            "Please note that the link for participants that you have already received to access the workshop will not work anymore.",
          Dash_MB_21_F:
            "To assist you, we have again sent you the email with the complete information needed by the participants. Please note that only the access link for the workshop is new. The remaining information is the same as for the previously scheduled workshop.",
          Dash_MB_22: "Date and/or time unavailable?",
          Dash_MB_23:
            "We are very sorry! Mission Team Digital Teambuilding is very popular and unfortunately, your chosen date and time are currently not available for a workshop.",
          Dash_MB_24:
            "However, we will provide best efforts to accommodate your request. Please contact us for assistance via",
          Dash_MB_25: "or call us",
          Dash_MB_26: "Reschedule",
          Dash_MB_27: "Email Sent!",
          Dash_MB_28: "Workshop details have been sent to",
          Dash_MB_29: "Close",
          Dash_MB_30: "Status",
          Dash_MB_31: "Download Teamprofile",

          Dash_MB_32: "Please purchase additional licenses",
          Dash_MB_33: "You need to purchase additional licenses to continue",
          Dash_MB_34: "Purchase now",
          Dash_MB_35: "Later",

          Dash_MB_36: "Reschedule?",
          Dash_MB_37:
            "By rescheduling a facilitated workshop, you will forfeit your facilitator license for this workshop. You can only reschedule if there is a facilitator license available on your account. Please make sure to first purchase a new facilitator license if there is none on your account.",
          Dash_MB_38: "Purchase facilitator license",
          Dash_MB_39: "Reschedule",

          Dash_AVB_H1: "Licenses",
          Dash_AVB_1: "Available participants licenses",
          Dash_AVB_1_1: "Available facilitator licenses",
          Dash_AVB_2: "Purchase additional licenses",
          Dash_AVB_3:
            " Before booking new workshops, you may need to buy additional licenses. Each License includes one Access Code to the Visual Implicit Profiler (VIP) and one Seat on the digital workshop.The count of available Licences is based on the number of persons allocated to workshops, whether these have taken place or are upcoming.",
          Dash_AVB_4: "1. Calculate Quote",
          Dash_AVB_5: "2. Order Summary",
          Dash_AVB_6: "3. Payment",
          Dash_AVB_7_DC_1: "Use our dynamic calculator to view a quote",
          Dash_AVB_8_DC_2: "Participants Licenses",
          Dash_AVB_8_DC_2_1: "Facilitator Licenses",
          Dash_AVB_8_DC_3: "Cost / License*",
          Dash_AVB_8_DC_4: "Total",
          Dash_AVB_8_DC_5: "Confirm",
          Dash_AVB_8_DC_6: "+ VAT",
          Dash_AVB_8_DC_7: "*VAT of 7.7% only applicable in Switzerland",
          TotalCost: "Total Cost",

          Dash_AVB_9_OS_1: "Order Summary",
          Dash_AVB_9_OS_2:
            "Please confirm or edit your order before proceeding to payment. ",
          Dash_AVB_9_OS_3: "Payment Type : ",
          Dash_AVB_9_OS_4: "Card",
          Dash_AVB_9_OS_5: "Direct Bank Transfer",
          Dash_AVB_9_OS_6: "Order Id",
          Dash_AVB_9_OS_7: "Product Name",
          Dash_AVB_9_OS_8: "Quantity",
          Dash_AVB_9_OS_9: "Unit Price",
          Dash_AVB_9_OS_10: "Total Price",
          Dash_AVB_9_OS_11: "Sum:",
          Dash_AVB_9_OS_12: "*VAT of 7.7% only \napplicable in Switzerland",
          Dash_AVB_9_OS_13: "Invoice amount:",
          Dash_AVB_9_OS_14: "VAT (7.7%):",
          Dash_AVB_9_OS_15: "SEATS",
          Dash_AVB_9_OS_16: "Proceed to payment",
          Dash_AVB_9_OS_17:
            "I wish to accept  the PSYfiers terms and conditions",

          Dash_AVB_10_THK_1: "Thank you for your order!",
          Dash_AVB_10_THK_2:
            "We have sent the following emails to your email address:",
          Dash_AVB_10_THK_3: "The receipt (invoice paid) for your order.",
          Dash_AVB_10_THK_4:
            "2. A list of all the VIP Access Codes that you have ordered.",
          Dash_AVB_10_THK_5:
            "The additional license(s) that you purchased are now in your account on the Customer Portal. You may increase the number of participants in an existing workshop or create new workshops.",
          Dash_AVB_10_THK_6:
            "If you have not received the e-mails, please check first in your SPAM folder. If you still don’t find the e-mail contact us via",
          Dash_AVB_10_THK_7: "or call us",

          workshopTypeModal: {
            h1: "Please select type of workshop you would like to book",
            self: "Self-moderated workshop",
            self_desc:
              "You wil assign your own own co-moderator to facilitate the workshop.",
            faciliated: "Facilitated workshop",
            faciliated_desc:
              "You will be provided with a  facilitator that assists you in running the workshop at a technical level.",
          },
          Dash_CRN_H1: "Create new workshop groups",
          Dash_CRN_H2: "Create your Mission Team workshop groups",
          Dash_CRN_H3:
            "We recommend staging each workshop with 4 to 12 team members. However, you may book between 2  and 14 participants per workshop.",
          Dash_CRN_H4: "Persons in group",
          Dash_CRN_H5: "Time approx needed",
          Dash_CRN_H6: "3 hours",
          Dash_CRN_H7: "Proceed",
          Dash_CRN_H8: "Available seats for workshop(s): ",
          Dash_CRN_H9: "Important Notice:",
          Dash_CRN_H10:
            "The workshop room will open 15 minutes before the scheduled time and close 3 hours after the scheduled time.",
          Dash_CRN_H11: "3. Select Language",
          Dash_CRN_H12: "4. Select Date&Time",
          Dash_CRN_H13: "2. Group Size",
          Dash_CRN_H13_0: "1. Workshop Type",
          Dash_CRN_H14: "Pick a date and a starting time for your workshop",
          Dash_CRN_H15: "Successfully booked!",
          Dash_CRN_H16: "Book New workshop",
          Dash_CRN_H17:
            "We have sent you an e-mail with two separate access links for the workshop (one for the co-moderator and one for the participant) and a link to the Visual Implicit Profiler (VIP).You can as of now manage this workshop booking on the Customer Portal (see Manage Existing Bookings).",
          Dash_CRN_H17_facilitated:
            "You will be provided with a  facilitator that assists you in running the workshop at a technical level.",
          Dash_CRN_H18:
            "If you have not received the e-mail, please check first in your SPAM folder. If you still don’t find the e-mail contact us via ",
          Dash_CRN_H19:
            "Before booking new workshops, you first need to buy the necessary amount of additional licenses.",

          modalMissionDetails: {
            missionDetails: " Mission Details",
            Noof: "No. of Participants",
            text1:
              "The Team Name will appear as the heading of your unique team profile!",
            timeslotHeader: "Pick a date and a starting time for your workshop",
            timeslotinfo:
              "Select the date of your workshop and then your desired time slot.",
            changeLanguage: "Change Language",
            datetimeunav: "Date or time unavailable ?",
            timezone: "Timezone",
            Isconfirm: "Confirm",
          },
          modallanguage: {
            languageHeader:
              "In what language would you like to stage the workshop?",
            en: "English",
            de: "German",
            it: "Italian",
            fr: "French",
            modaltext:
              "Please note that the choice of language for the workshop also determines the language of the Visual Implicit Profiler (VIP).  Your language choice cannot be changed once the groups are confirmed in the next step.",
            mod_italian_unavailable:
              "Please note that facilitated workshops are not available in Italian yet. Please select a workshop without facilitator.",
          },
          modalIWorkshopDeleted: {
            h1: "Workshop Deleted!",
            part1: "You have deleted an existing workshop.",
            part2:
              "The number of Seats allocated to the deleted workshop has now become available again. You may book a new workshop.",
          },
          modalDelete: {
            h1d: "Delete Booking?",
            pd: "Are you sure you would like to delete this booking",
            nokeep: "No Keep",
            yesDelete: "Yes Delete",
          },

          Dash_AC_H1: "Your account",
          Dash_AC_H2: "Update account details",
          Dash_AC_H3: "Contact details and purchase history",
        },
        UpdateForm: {
          UF_GoBack: "Go Back",
          UF_MyAccount: "My Account",
          UF_BTNTxt: "Purchase History",
          UF_SubTxt: "Update Your Company Details",
          UF_DelAcc: "Delete account?",
          UF_Confm: "Are you sure you would like to delete this account?",
          UF_Cnf1: "No keep",
          UF_Cnf2: "Yes delete",
          UF_Acc_Del: "Your account has been deleted!",
          UF_Acc_Del_Cnf: "You have deleted your account.",
          Uf_Acc_Mist:
            "If this was a mistake, please contact our account manager via",
          UF_Call_Txt: "or call us",
          UF_BtnSave: "Save",
          UF_Lst_Upd: "Last Updated",
          UF_Dl_Pf: "Delete profile X",
          PC_1: "Organisation",
          PC_2: "First Name",
          PC_3: "last Name",
          PC_4: "House Number",
          PC_5: "Town",
          PC_6: "Email",
          PC_7: "Address",
          PC_8: "Post Code",
          PC_9: "",
          PC_10: "",
          PC_11: "",
        },
        ViewAll: {
          VH_goBack: "Go Back",
          VH_upcoming: "Upcoming",
          VH_past: "Past",
          VH_Ttl: "Manage upcoming workshops",
          VH_SubTtl:
            "You may change the booking and reschedule the workshop any time until 24 hours before the workshop",
          VH_Wksbkd: "Workshops booked",
          VH_LSt_H1: "Workshop Date",
          VH_LSt_H2: "Workshop Time",
          VH_LSt_H3: "Group Size",
          VH_LSt_H4: "Team Name",
          VH_LSt_H5: "Language",
          VH_LSt_H6: "Change Booking",
          VH_noData: "No Data Found",
          VH_Res: "Reschedule",
        },
        PurchaseHist: {
          PH_goBack: "Go Back",
          PH_Ttl: "Purchase History",
          PH_LSt_H1: "Order Number",
          PH_LSt_H2: "Purchase Date",
          PH_LSt_H3: "Facilitator License",
          PH_LSt_H4: "Participant License",
          PH_LSt_H5: "Paid",
          PH_LSt_H6: "Order Form",
          PH_LSt_H7: "VIP Access Codes",
          PH_View: "View",
          PH_Download: "Download",
        },
        thankYouSalesTrue: {
          //h1: "Thank you for contacting our sales team!",
          PurchaseInvoice: {
            h2: "Thank you for your order!",
            part1: "We have sent the following emails to your email address:",
            part1_a: "1. The receipt (invoice paid) for your order.",
            part1_b:
              "2. A list of all the VIP Access Codes that you have ordered.",
            part1_c:
              "The additional license(s) that you purchased are now in your account on the Customer Portal. You may increase the number of participants in an existing workshop or create new workshops.",
            part2:
              "If you have not received the e-mail, please check first in your SPAM folder. If you still don’t find the e-mail contact us via",
            email: "booking@mission-team.com",
            part3: "or call us",
            phone: "+41 41 500 04 77",
          },
          BankTransfer: {
            h2: "Your invoice is on the way!",
            maintxt:
              "We have sent an invoice to your email address. As soon as your payment has been received, we will send you the additional VIP Access Code(s) and increase the number of available Seats in your account on the Customer Portal.",
            part2:
              "If you have not received the e-mail, please check first in your SPAM folder. If you still don’t find the e-mail contact us via",
            cemail: "booking@mission-team.com",
            part3: "or call us",
            phone: "+41 41 500 04 77",
          },
        },
        workshopDetailsPage: {
          workshopHeading: "Workshop",
          resendBookingEmail: "Resend Booking Email",
          p_links: "Participant Links",
          vip_access_links: "VIP Access Link",
          vip_access_links_info:
            "Particiants must do the VIP test before entering the workshop.",
          copy_link: "Copy Link",
          system_check_link: "System Check Link",
          system_check_link_info:
            "Use this link to verify if your browser is supported.",
          vip_access_codes: "VIP Access Codes",
          vip_access_codes_view: "View all your  VIP Codes",
          p_access_link: "Participant Workshop Access Link",
          p_links_info:
            "All email templates and workshop details can be viewed in the booking email.",
          m_links: "Moderator Links",
          m_training_link: "Moderator Training Link",
          m_training_link_info: "Learn how to facilitate a workshop here.",
          m_workshop_access_link: "Moderator Workshop Access Link",
          m_workshop_access_link_info: "Use this link to access the workshop",
          m_checklist: "Moderator Checklist",
          m_checklist_download: "Download Now",
          m_training_link_link:
            "https://app.spatial.chat/s/MissionTeam-ModeratorTraining_EN1",
          m_checklist_link:
            "https://drive.google.com/file/d/1LruQWQRqO3enDoN9iMXJpkuYFLSEG56S/view?usp=sharing",
          past_teamprofile_download: "Download",
          past_participants_heading: "Participants",
          past_participants_info: "All participants that joined the workshop",
          past_mod: "Co-Moderator / Facilitator",
          past_mod_info: "Whether a participant or not",
          past_mod_button: "Co-Moderator",
        },
      },
      de: {
        LoginPage: {
          Login_h1_1: "Meine Buchungen verwalten",
          Login_h1_2: "Mission Team Digital Teambuilding",
          Login_SubTxt:
            "Bitte geben Sie Ihren persönlichen Kunden-Identifikationscode ein, um Ihre Buchungen zu verwalten",
          Login_Code_WtMrk: "Code Eingeben",
          Login_Code_BTntxt: "Weiter",
          Login_forgtlnk_1: "Kunden-Identifikationscode vergessen?",
          Login_forgtlnk_2: "Klicken Sie hier",
          Login_Frgtbx: "Neuen Kunden-Identifikationscode anfordern",
          Login_Frgtbx_subtxt1:
            "Bitte geben Sie Ihre E-Mail-Adresse ein, damit wir Ihnen einen neuen Code zusenden können.",
          Login_Frgtbx_subtxt2: "Senden",
          Login_Frgtbx_subtxt3: "Benötigen Sie Hilfe?",
          Login_Frgtbx_subtxt4: "Ihr Code wurde verschickt",
          Login_Frgtbx_subtxt5:
            "Ihr neuer Kunden-Identifikationscode wurde verschickt!",
          Login_Frgtbx_subtxt6:
            "Falls Sie die entsprechende E-Mail nicht erhalten haben, prüfen Sie bitte Ihren SPAM-Ordner. Finden Sie die E-Mail auch dort nicht, kontaktieren Sie uns via",
          Login_Frgtbx_subtxt7: "oder Telefonnummer",
        },
        Dashboard: {
          Dash_MainH1: "Vorhandene Buchungen verwalten",
          Dash_Viewall: "View All",
          Dash_Yes: "Ja",
          Dash_No: "Nein",
          Dash_MB_H1: "gebuchte Workshops",
          Dash_MB_1: "Workshop Datum",
          Dash_MB_2: "Workshop Zeit",
          Dash_MB_3: "Anzahl Personen",
          Dash_MB_3_1: "Betreuer*in",
          Dash_MB_4: "Name des Teams",
          Dash_MB_5: "Sprache",
          Dash_MB_6: "Buchung ändern",
          Dash_MB_6_1: "Info",
          Dash_MB_7:
            "Wenn Sie einen betreuten Workshop verschieben, verfällt die Betreuerlizenz. Sie können jedoch bis 24 Stunden vor dem Workshop in jedem Fall die Buchung ändern und den Workshop neu ansetzen, ohne, dass die Teilnehmerlizenzen verfallen.",
          Dash_MB_8: "Aktualisierung der Zahl der Teilnehmenden",
          Dash_MB_9:
            "Wenn Sie eine/n Teilnehmer*in hinzugefügt haben, vergessen Sie bitte nicht, dieser Person einen VIP-Zugangscode sowie den Link zum VIP und den Link zum Workshop zu schicken.",
          Dash_MB_10:
            "Wenn Sie eine/n Teilnehmer*in gelöscht haben, können Sie den unbenutzten VIP-Zugangscode an einen andere Person für diesen Workshop oder für einen neuen Workshop verwenden.",
          Dash_MB_11: "Workshop gelöscht!",
          Dash_MB_12: "Sie haben einen gebuchten Workshop gelöscht.",
          Dash_MB_13:
            "Die Anzahl der Plätze, die dem gelöschten Workshop zugewiesen wurde, ist nun wieder verfügbar. Sie können einen neuen Workshop buchen.",
          Dash_MB_14: "Buchung löschen?",
          Dash_MB_15:
            "Sind Sie sicher, dass Sie diese Buchung löschen möchten?",
          Dash_MB_16: "Nein - Behalten",
          Dash_MB_17: "Ja - Löschen",
          Dash_MB_18:
            "Wählen Sie das Datum und die Startzeit für Ihren Workshop",

          Dash_MB_19: "Datum und/oder Zeitfenster des Workshops aktualisiert!",
          Dash_MB_20:
            "Bitte beachten Sie, dass die beiden Links (Teilnehmende / Moderator*in), die Sie bereits erhalten haben, um zum Workshop zu gelangen, nicht mehr funktionieren.",
          Dash_MB_21:
            "Um Ihnen alle Information auf einmal zur Verfügung zu stellen, haben wir Ihnen noch einmal die E-Mail mit den vollständigen Informationen für die Teilnehmenden und den/die Co-Moderator*in geschickt. Bitte beachten Sie jedoch, dass nur die beiden separaten Zugangslinks für den Workshop (Teilnehmende / Moderator*in) neu sind. Die übrigen Informationen haben sich nicht geändert. ",
          Dash_MB_20_F:
            "Bitte beachten Sie, dass der Link, die Sie bereits erhalten haben, demit die Teilnehmenden zum Workshop gelangen können, nicht mehr funktioniert.",
          Dash_MB_21_F:
            "Um Ihnen alle Information auf einmal zur Verfügung zu stellen, haben wir Ihnen noch einmal die E-Mail mit den vollständigen Informationen für die Teilnehmenden geschickt. Bitte beachten Sie jedoch, dass nur der Zugangslink für den Workshop (Teilnehmende) neu ist. Die übrigen Informationen haben sich nicht geändert.",
          Dash_MB_22: "Datum und/oder Zeitfenster nicht verfügbar?",
          Dash_MB_23:
            "Es tut uns sehr leid! Mission Team Digital Teambuilding ist sehr beliebt und leider ist das von Ihnen gewählte Zeitfenster an diesem Datum derzeit nicht für einen Workshop verfügbar.",
          Dash_MB_24:
            "Wir werden jedoch unser Bestes tun, um Ihre Anfrage zu berücksichtigen. Bitte kontaktieren Sie uns über",
          Dash_MB_25: "oder rufen Sie uns an unter",

          Dash_MB_26: "Ändern",
          Dash_MB_27: "E-Mail verschickt!",
          Dash_MB_28: "Die Workshop Details wurden verschickt an",
          Dash_MB_29: "Schliessen",
          Dash_MB_30: "Status",
          Dash_MB_31: "Teamprofil herunterladen",

          Dash_MB_32: "Bitte erwerben Sie zusätzliche Lizenzen",
          Dash_MB_33:
            "Sie müssen zusätzliche Lizenzen erwerben, um fortzufahren",
          Dash_MB_34: "Jetzt kaufen",
          Dash_MB_35: "Später",

          Dash_MB_36: "Verschieben?",
          Dash_MB_37:
            "Wenn Sie einen moderierten Workshop verschieben, verfällt die dazugehörende Betreuerlizenz. Sie können einen Workshop also nur verschieben, wenn auf Ihrem Konto eine Moderatorenlizenz verfügbar ist. Bitte erwerben Sie zuerst eine neue Moderatorenlizenz, wenn noch keine auf Ihrem Konto vorhanden ist.",
          Dash_MB_38: "Betreuerlizenz erwerben",
          Dash_MB_39: "Verschieben",

          Dash_AVB_H1: "Lizenzen",
          Dash_AVB_1: "Verfügbare Teilnehmerlizenzen",
          Dash_AVB_1_1: "Verfügbare Betreuerlizenzen",
          Dash_AVB_2: "Zusätzliche Lizenzen kaufen",
          Dash_AVB_3:
            "Bevor Sie neue Workshops buchen, müssen Sie eventuell zusätzliche Lizenzen erwerben. Jede Lizenz umfasst einen Zugangscode für den Visual Implicit Profiler (VIP) und einen Platz für den digitalen Workshop. Die Anzahl der verfügbaren Lizenzen richtet sich nach der Anzahl der Personen, die den Workshops zugewiesen wurden, unabhängig davon, ob diese bereits stattgefunden haben oder noch bevorstehen.",
          Dash_AVB_4: "1. Angebot berechnen",
          Dash_AVB_5: "2. Bestätigung",
          Dash_AVB_6: "3. Zahlung",
          Dash_AVB_7_DC_1: "Unser dynamischer Rechner berechnet Ihr Angebot",
          Dash_AVB_8_DC_2: "Teilnehmerlizenzen",
          Dash_AVB_8_DC_2_1: "Betreuerlizenzen",
          Dash_AVB_8_DC_3: "Kosten / Lizenz*",
          Dash_AVB_8_DC_4: "Total",
          Dash_AVB_8_DC_5: "Weiter",
          Dash_AVB_8_DC_6: "*Zzgl. MwSt. (nur in der Schweiz anwendbar)",
          Dash_AVB_8_DC_7:
            "* Mehrwertsteuer von 7,7 %  nur in der Schweiz anwendbar",
          TotalCost: "Gesamtkosten",

          Dash_AVB_9_OS_1: "Bestellbestätigung",
          Dash_AVB_9_OS_2:
            "Bitte bestätigen Sie Ihre Bestellung und wählen Sie Ihre Zahlungsmethode.",
          Dash_AVB_9_OS_3: "Zahlungsart : ",
          Dash_AVB_9_OS_4: "karte",
          Dash_AVB_9_OS_5: "Banküberweisung",
          Dash_AVB_9_OS_6: "Bestellungs-ID",
          Dash_AVB_9_OS_7: "Produkt",
          Dash_AVB_9_OS_8: "Anzhal Lizenzen",
          Dash_AVB_9_OS_9: "Preis pro Einheit",
          Dash_AVB_9_OS_10: "Gesamtpreis",
          Dash_AVB_9_OS_11: "Lizenzen:",
          Dash_AVB_9_OS_12:
            "* Mehrwertsteuer von 7,7 %  \nnur in der Schweiz anwendbar",
          Dash_AVB_9_OS_13: "Lizenzkosten:",
          Dash_AVB_9_OS_14: "* MwSt. (7.7 %):",
          Dash_AVB_9_OS_15: "Gesamtkosten",
          Dash_AVB_9_OS_16: "Weiter zur Zahlung",
          Dash_AVB_9_OS_17:
            "Ich akzeptiere die allgemeinen Geschäftsbedingungen von PSYfiers",

          Dash_AVB_10_THK_1: "Thank you for your order!",
          Dash_AVB_10_THK_2:
            "We have sent the following e-mails to your e-mail address:",
          Dash_AVB_10_THK_3: "1.The receipt (invoice paid) for your order.",
          Dash_AVB_10_THK_4:
            "2.A list of all the VIP Access Codes that you have ordered.",
          Dash_AVB_10_THK_5:
            "The additional license(s) that you purchased are now in your account on the Customer Portal. You may increase the number of participants in an existing workshop or create new workshops.",
          Dash_AVB_10_THK_6:
            "If you have not received the e-mails, please check first in your SPAM folder. If you still don’t find the e-mail contact us via",
          Dash_AVB_10_THK_7: "or call us",

          workshopTypeModal: {
            h1: "Bitte wählen Sie die Art des Workshops, den Sie buchen möchten.",
            self: "Selbst-moderierter Workshop",
            self_desc:
              "Sie nutzen eine*n eigene*n Co-Moderator*in, um den Workshop zu betreuen.",
            faciliated: "Betreuter Workshop",
            faciliated_desc:
              "Ihnen wird ein*e Betreuer*in zugewiesen, der Sie bei der Durchführung des Workshops auf operativer Ebene unterstützt.",
          },
          Dash_CRN_H1: "Neuen Workshop erstellen",
          Dash_CRN_H2: "Erstellen Sie Ihren Mission Team Workshop",
          Dash_CRN_H3:
            "Wir empfehlen, jeden Workshop mit 4 bis 12 Teammitgliedern zu veranstalten. Sie können jedoch zwischen 2 und 14 Teilnehmenden pro Workshop buchen.",
          Dash_CRN_H4: "Anzahl Personen",
          Dash_CRN_H5: "Ungefähr benötigte Zeit",
          Dash_CRN_H6: "3 Stunden",
          Dash_CRN_H7: "Weiter",
          Dash_CRN_H8: "Verfügbare Plätze für den/die Workshop(s):",
          Dash_CRN_H9: "Wichtiger Hinweis:",
          Dash_CRN_H10:
            "Der Zugang zum Workshop wird 15 Minuten vor der festgelegten Zeit freigegeben. 3 Stunden nach der vorgesehen Startzeit wird der Zugang gesperrt.",
          Dash_CRN_H11: "3. Wählen Sie die Sprache",
          Dash_CRN_H12: "4. Wählen Sie Datum und Uhrzeit",
          Dash_CRN_H13: "2. Anzahl Personen",
          Dash_CRN_H13_0: "1. Art des Workshops",
          Dash_CRN_H14: "Pick a date and a starting time for your workshop",
          Dash_CRN_H15: "Workshop erfolgreich gebucht!",
          Dash_CRN_H16: "Neuen Workshop buchen",
          Dash_CRN_H17:
            "Wir haben Ihnen eine E-Mail mit zwei separaten Zugangslinks für den Workshop (einen für den/die Co-Moderator*in und einen für die Teammitglieder) sowie einen Link zum Visual Implicit Profiler (VIP) geschickt. Sie können diese Workshop-Buchung ab sofort über das Kundenportal verwalten",
          Dash_CRN_H17_facilitated:
            "Ihnen wird ein*e Betreuer*in zugewiesen, der Sie bei der Durchführung des Workshops auf operativer Ebene unterstützt.",
          Dash_CRN_H18:
            "Wenn Sie die E-Mail nicht erhalten haben, sehen Sie bitte zuerst in Ihrem SPAM-Ordner nach. Wenn Sie die E-Mail immer noch nicht finden, kontaktieren Sie uns über booking@mission-team.com oder rufen Sie uns an unter",
          Dash_CRN_H19:
            "Bevor Sie neue Workshops buchen, müssen Sie eventuell zusätzliche Lizenzen erwerben.",

          modalMissionDetails: {
            missionDetails: "Angaben zur Mission",
            Noof: "Anzahl Teilnehmende:",
            text1:
              "Der Name des Teams wird als Überschrift Ihres einzigartigen Teamprofils erscheinen!",
            timeslotHeader:
              "Wählen Sie das Datum und die Startzeit für Ihren Workshop",
            timeslotinfo:
              "Wählen Sie zunächst das Datum  und dann das gewünschte Zeitfenster.",
            changeLanguage: "Sprache ändern",
            datetimeunav: "Datum und/oder Zeitfenster nicht verfügbar?",
            timezone: "Zeitzone",
            Isconfirm: "Bestätigen",
          },
          modallanguage: {
            languageHeader:
              "In welcher Sprache möchten Sie den Workshop durchführen?",
            en: "Englisch",
            de: "Deutsch",
            it: "Italienisch",
            fr: "Französisch",
            modaltext:
              "Bitte beachten Sie, dass die Wahl der Sprache für den Workshop auch die Sprache des Visual Implicit Profilers (VIP) bestimmt. Die Sprachwahl kann nicht mehr geändert werden, sobald der Workshop im nächsten Schritt bestätigt wurden.",
            mod_italian_unavailable:
              "Leider sind zur Zeit noch keine betreuten Workshops auf italienisch verfügbar. Bitte wählen Sie einen Workshop ohne Betreuer*in.",
          },
          modalIWorkshopDeleted: {
            h1: "Workshop Deleted!",
            part1: "You have deleted an existing workshop.",
            part2:
              "The number of Seats allocated to the deleted workshop has now become available again. You may book a new workshop.",
          },
          modalDelete: {
            h1d: "Delete Booking?",
            pd: "Are you sure you would like to delete this booking",
            nokeep: "No Keep",
            yesDelete: "Yes Delete",
          },

          Dash_AC_H1: "Ihr Account",
          Dash_AC_H2: "Kontodaten aktualisieren",
          Dash_AC_H3: "Kontaktinformationen und Bestellhistorie",
        },
        UpdateForm: {
          UF_GoBack: "Go back",
          UF_MyAccount: "Meine Kontaktdaten",
          UF_BTNTxt: "Bestellhistorie",
          UF_SubTxt: "Aktualisieren Sie Ihre Kontakdaten",
          UF_DelAcc: "Account löschen?",
          UF_Confm: "Sind Sie sicher, dass Sie diesen Account löschen wollen?",
          UF_Cnf1: "Nein - Behalten",
          UF_Cnf2: "Ja - Löschen",
          UF_Acc_Del: "Ihr Account wurde gelöscht!",
          UF_Acc_Del_Cnf: "Sie haben Ihren Account gelöscht.",
          Uf_Acc_Mist:
            "Wenn dies ein Irrtum war, bitte kontaktieren Sie uns  via",
          UF_Call_Txt: "oder rufen Sie an auf",
          UF_BtnSave: "Speichern",
          UF_Lst_Upd: "Zuletzt aktualisiert",
          UF_Dl_Pf: "Profil löschen X",
          PC_1: "Organisation",
          PC_2: "Vorname",
          PC_3: "Nachname",
          PC_4: "Hausnummer",
          PC_5: "Stadt",
          PC_6: "E-Mail",
          PC_7: "Addresse",
          PC_8: "Postleitzahl",
          PC_9: "",
          PC_10: "",
          PC_11: "",
        },
        ViewAll: {
          VH_goBack: "Go Back",
          VH_upcoming: "Anstehend",
          VH_past: "Durchgeführt",
          VH_Ttl: "Anstehende Workshops verwalten",
          VH_SubTtl:
            "Sie können die Buchung sowie Datum/Uhrzeit bis 24 Stunden vor dem Workshop verändern",
          VH_Wksbkd: "gebuchte Workshops",
          VH_LSt_H1: "Workshop Datum",
          VH_LSt_H2: "Workshop Zeit",
          VH_LSt_H3: "Personen",
          VH_LSt_H4: "Name des Teams",
          VH_LSt_H5: "Sprache",
          VH_LSt_H6: "Buchung ändern",
          VH_Res: "Ändern",
          VH_noData: "Keine Daten gefunden",
        },
        PurchaseHist: {
          PH_goBack: "Go back",
          PH_Ttl: "Bestellhistorie",
          PH_LSt_H1: "Bestellnummer",
          PH_LSt_H2: "Kaufdatum",
          PH_LSt_H3: "Betreuerlizenzen",
          PH_LSt_H4: "Teilnehmerlizenzen",
          PH_LSt_H5: "Bezahlt",
          PH_LSt_H6: "Bestellung",
          PH_LSt_H7: "VIP Zugangscodes",
          PH_View: "Ansehen",
          PH_Download: "Herunterladen",
        },
        thankYouSalesTrue: {
          // h1: "Thank you for contacting our sales team!",
          PurchaseInvoice: {
            h2: "Vielen Dank für Ihre Bestellung!",
            part1:
              "Wir haben die folgenden E-Mails an Ihre E-Mail-Adresse gesendet:",
            part1_a: "1. Die Quittung (bezahlte Rechnung) für Ihre Bestellung.",
            part1_b:
              "2. Eine Liste aller VIP-Zugangscodes, die Sie bestellt haben",
            part1_c:
              "Die zusätzliche(n) Lizenz(en), die Sie erworben haben, befinden sich jetzt in Ihrem Account im Kundenportal. Sie können die Anzahl der Teilnehmenden an einem bestehenden Workshop erhöhen oder neue Workshops erstellen.",
            part2:
              "Sollten Sie eine oder beide dieser E-Mails nicht erhalten haben, schauen Sie bitte zuerst in Ihrem SPAM-Ordner nach. Wenn Sie die E-Mails immer noch nicht finden, kontaktieren Sie uns via",
            email: "booking@mission-team.com",
            part3: "oder rufen Sie uns an auf",
            phone: "+41 41 500 04 77",
          },
          BankTransfer: {
            h2: "Vielen Dank für Ihre Bestellung! ",
            maintxt:
              "Wir haben Ihnen eine Rechnung an Ihre E-Mail-Adresse geschickt. Sobald Ihre Zahlung bei uns eingegangen ist, werden wir Ihnen alle Dokumente, Links und Zugangscodes zukommen lassen, die Sie für einen erfolgreichen Mission Team Workshop benötigen.",
            part2:
              "Sollten Sie eine oder beide dieser E-Mails nicht erhalten haben, schauen Sie bitte zuerst in Ihrem SPAM-Ordner nach. Wenn Sie die E-Mails immer noch nicht finden, kontaktieren Sie uns via",
            cemail: "booking@mission-team.com",
            part3: "oder rufen Sie uns an auf",
            phone: "+41 41 500 04 77",
          },
        },
        workshopDetailsPage: {
          workshopHeading: "Workshop",
          resendBookingEmail: "Buchungs-E-Mail neu versenden",
          p_links: "Teilnehmerlinks",
          vip_access_links: "VIP Zugangslink",
          vip_access_links_info:
            "Die Teilnehmenden müssen den VIP vor dem Workshop durchführen.",
          copy_link: "Link kopieren",
          system_check_link: "Systemcheck Link",
          system_check_link_info:
            "Nutzen Sie diesen Link, um Ihre Systemeinstellungen zu prüfen.",
          vip_access_codes: "VIP Zugangscodes",
          vip_access_codes_view: "Sehen Sie sich alle Ihre VIP Codes an ",
          p_access_link: "Workshop Zugangslink für die Teilnehmenden",
          p_links_info:
            "Benutzen Sie diesen Link, um als Moderator in den Workshop zu gelangen.",
          m_links: "Moderatoren*innen Links",
          m_training_link: "Moderatoren*innen Trainingslink",
          m_training_link_info:
            "Lernen Sie die Aufgaben der/des Co-Moderators*in kennen.",
          m_workshop_access_link:
            "Workshop Zugangslink für den/die Co-Moderator*in",
          m_workshop_access_link_info:
            "Benutzen Sie diesen Link, um als Moderator in den Workshop zu gehen.",
          m_checklist: "Moderations-Checkliste",
          m_checklist_download: "Herunterladen",
          m_training_link_link:
            "https://app.spatial.chat/s/MissionTeam-ModeratorTraining_DE1",
          m_checklist_link:
            "https://drive.google.com/file/d/1kw_QxBq134knUxqYm6MgMGup19Z5iIat/view?usp=sharing",
          past_teamprofile_download: "Herunterladen",
          past_participants_heading: "Teilnehmende",
          past_participants_info: "Alle Teilnehmende des Workshops",
          past_mod: "Co-Moderator*in / Betreuer*in",
          past_mod_info: "Ob Teilnehmende*r oder nicht",
          past_mod_button: "Co-Moderator*in",
        },
      },

      fr: {
        LoginPage: {
          Login_h1_1: "Gérer mes commandes",
          Login_h1_2: "Mission Team Digital Teambuilding",
          Login_SubTxt:
            "Veuillez insérer votre Code d’Identification Client  pour accéder au Portail Client",
          Login_Code_WtMrk: "Insérez le code",
          Login_Code_BTntxt: "Continuer",
          Login_forgtlnk_1: "Oublié le code d’Identification ?",
          Login_forgtlnk_2: "Cliquez ici",
          Login_Frgtbx: "Demander un nouveau code",
          Login_Frgtbx_subtxt1:
            "Insérer votre adresse courriel et nous vous enverrons un nouveau Code d’Identification Client",
          Login_Frgtbx_subtxt2: "Nouveau Code",
          Login_Frgtbx_subtxt3: "Avez-vous besoin de plus de support ?",
          Login_Frgtbx_subtxt4: "Votre code a été envoyé !",
          Login_Frgtbx_subtxt5:
            "Votre nouveau Code d’Identification Client a été envoyé.",
          Login_Frgtbx_subtxt6:
            "Si vous n'avez pas reçu le courriel, vérifiez d'abord dans votre dossier SPAM. Si vous ne trouvez toujours pas le courriel, contactez-nous par courriel",
          Login_Frgtbx_subtxt7: "ou appelez-nous au",
        },
        Dashboard: {
          Dash_MainH1: "Gérer les ateliers existants",
          Dash_Viewall: "Voir tous",
          Dash_Yes: "Oui",
          Dash_No: "Non",
          Dash_MB_H1: "ateliers à venir",
          Dash_MB_1: "Date de l’atelier",
          Dash_MB_2: "Heure de l’atelier",
          Dash_MB_3: "Nombre de personnes",
          Dash_MB_3_1: "Facilitateur",
          Dash_MB_4: "Nom du team",
          Dash_MB_5: "Langue",
          Dash_MB_6: "Modifier la commande",
          Dash_MB_6_1: "Info",
          Dash_MB_7:
            "Si vous reprogrammez un atelier facilité, la licence de facilitateur sera perdue. Toutefois, vous pouvez modifier la réservation et reporter l'atelier à tout moment jusqu'à 24 heures avant l'atelier sans perdre les licences de participants.",
          Dash_MB_8: "Nombre de participants actualisé !",
          Dash_MB_9:
            "Si vous avez ajouté un participant, n'oubliez pas d'envoyer un Code d'Accès VIP avec le lien correspondant ainsi que le lien pour accéder à l'atelier au nouveau participant.",
          Dash_MB_10:
            "Si vous avez supprimé un participant, vous pouvez envoyer le Code d'Accès VIP non utilisé à un autre participant pour cet atelier ou pour un nouvel atelier.",
          Dash_MB_11: "Atelier supprimé !",
          Dash_MB_12: "Vous avez supprimé un atelier commandé.",
          Dash_MB_13:
            "Le nombre de places allouées à l'atelier supprimé est à nouveau disponible. Vous pouvez réserver un nouvel atelier.",
          Dash_MB_14: "Supprimer l’atelier ?",
          Dash_MB_15: "Êtes-vous sûr de vouloir supprimer cet atelier ?",
          Dash_MB_16: "Non - Garder",
          Dash_MB_17: "Oui - Supprimer",
          Dash_MB_18:
            "Modifiez la date et/ou la plage horaire de votre atelier",
          Dash_MB_19: "Mise à jour de la date et/ou de l'heure de l'atelier !",
          Dash_MB_20:
            "Veuillez noter que les deux liens (participants / modérateur) que vous avez déjà reçus pour accéder à l'atelier ne fonctionneront plus.",
          Dash_MB_21:
            "Nous vous avons envoyé encore une fois le courriel contenant toutes les informations nécessaires aux participants et au modérateur. Veuillez noter que seuls les deux liens d'accès pour l'atelier (participant (/ modérateur) sont nouveaux. Le reste des informations n'a pas changé et vous a déjà été envoyé une fois.",
          Dash_MB_20_F:
            "Veuillez noter que le lien que vous avez déjà reçus pour permettre aux participants accéder à l'atelier ne fonctionnera plus.",
          Dash_MB_21_F:
            "Nous vous avons envoyé endore une fois le courriel contenant toutes les informations nécessaires aux participants. Veuillez noter que seul le lien d'accès pour l'atelier (participants) est nouveau. Le reste des informations n'a pas changé et vous a déjà été envoyé une fois.",
          Dash_MB_22: "Date ou plage horaire non disponible ?",
          Dash_MB_23:
            "Nous sommes désolés ! Mission Team Digital Teambuilding est très populaire et malheureusement, la date et/ou la plage horaire que vous avez choisie n'est actuellement pas disponible pour un atelier.",
          Dash_MB_24:
            "Toutefois, nous ferons tout notre possible pour satisfaire à votre demande. Veuillez nous contacter pour obtenir plus de renseignements par courriel sur",
          Dash_MB_25: "ou appelez-nous au",

          Dash_MB_26: "Modifier",
          Dash_MB_27: "Courriel envoyé",
          Dash_MB_28: "Les détails de l’atelier ont été envoyés à",
          Dash_MB_29: "Fermer",
          Dash_MB_30: "Statut",
          Dash_MB_31: "Télécharger le profil de l’équipe",

          Dash_MB_32: "Veuillez acheter des licences supplémentaires",
          Dash_MB_33:
            "Vous devez acquérir des licences supplémentaires pour continuer",
          Dash_MB_34: "Acheter",
          Dash_MB_35: "Plus tard",

          Dash_MB_36: "Reprogrammer ?",
          Dash_MB_37:
            "Si vous reprogrammez un atelier facilité, la licence de facilitateur pour cet atelier sera perdue. Vous ne pouvez donc reprogrammer un atelier que si une licence de facilitateur est disponible sur votre compte. Veuillez d'abord acheter une nouvelle licence de présentateur si vous n'en avez plus sur votre compte.",
          Dash_MB_38: "Acheter une licence de facilitateur",
          Dash_MB_39: "Reprogrammer",

          Dash_AVB_H1: "Licenses",
          Dash_AVB_1: "Licences participants disponibles",
          Dash_AVB_1_1: "Licences facilitateur disponibles",
          Dash_AVB_2: "Acheter des licences  supplémentaires",
          Dash_AVB_3:
            "Avant de commander un nouvel atelier, vous devrez peut-être acheter des licences supplémentaires. Chaque licence comprend un Code d'Accès au Visual Implicit Profiler (VIP) et une place dans l'atelier numérique. Le nombre de licences disponibles est basé sur le nombre de personnes affectées aux ateliers, que ceux-ci aient eu lieu ou soient à venir.",
          Dash_AVB_4: "1. calculer un devis",
          Dash_AVB_5: "2. Résumé de l'ordre",
          Dash_AVB_6: "3.  Paiement",
          Dash_AVB_7_DC_1: "Notre calculateur dynamique chiffre votre offre.",
          Dash_AVB_8_DC_2: "Licences participants",
          Dash_AVB_8_DC_2_1: "Licences Facilitateur",
          Dash_AVB_8_DC_3: "Coût / Licence*",
          Dash_AVB_8_DC_4: "Coût total",
          Dash_AVB_8_DC_5: "Confirmer",
          Dash_AVB_8_DC_6: "* plus TVA si applicable (seulement en Suisse)",
          Dash_AVB_8_DC_7: "* La TVA de 7,7 % s'applique uniquement en Suisse.",
          TotalCost: "Coût total",

          Dash_AVB_9_OS_1: "Confirmation de commande",
          Dash_AVB_9_OS_2: "Veuillez confirmer ou modifier votre commande.",
          Dash_AVB_9_OS_3: "Type de paiement :",
          Dash_AVB_9_OS_4: "Carte",
          Dash_AVB_9_OS_5: "Virement bancaire",
          Dash_AVB_9_OS_6: "ID de la commande",
          Dash_AVB_9_OS_7: "Nom du produit",
          Dash_AVB_9_OS_8: "Nombre total de licences",
          Dash_AVB_9_OS_9: "Prix unitaire",
          Dash_AVB_9_OS_10: "Prix total",
          Dash_AVB_9_OS_11: "Licences:",
          Dash_AVB_9_OS_12:
            "* La TVA de 7,7 % \ns'applique uniquement en Suisse.",
          Dash_AVB_9_OS_13: "Coût des produits :",
          Dash_AVB_9_OS_14: "TVA* (7,7 %)",
          Dash_AVB_9_OS_15: "Coût total",
          Dash_AVB_9_OS_16: "Continuer vers paiement",
          Dash_AVB_9_OS_17: "J'accepte les conditions générales de PSYfiers",

          Dash_AVB_10_THK_1: "Thank you for your order!",
          Dash_AVB_10_THK_2:
            "We have sent the following e-mails to your e-mail address:",
          Dash_AVB_10_THK_3: "1.The receipt (invoice paid) for your order.",
          Dash_AVB_10_THK_4:
            "2.A list of all the VIP Access Codes that you have ordered.",
          Dash_AVB_10_THK_5:
            "The additional license(s) that you purchased are now in your account on the Customer Portal. You may increase the number of participants in an existing workshop or create new workshops.",
          Dash_AVB_10_THK_6:
            "If you have not received the e-mails, please check first in your SPAM folder. If you still don’t find the e-mail contact us via",
          Dash_AVB_10_THK_7: "or call us",

          workshopTypeModal: {
            h1: "Veuillez sélectionner le type d'atelier que vous souhaitez acquérir.",
            self: "Atelier auto-modéré",
            self_desc:
              "Vous désignerez votre propre co-modérateur pour faciliter l'atelier.",
            faciliated: "Atelier avec facilitateur",
            faciliated_desc:
              "Vous aimeriez avoir un facilitateur qui vous aide à gérer l'atelier à un niveau technique.",
          },
          Dash_CRN_H1: "Créer un nouvel atelier",
          Dash_CRN_H2: "Créer un atelier Mission Team",
          Dash_CRN_H3:
            "Nous recommandons d'organiser chaque atelier avec 4 à 12 membres d'équipe. Toutefois, vous pouvez réserver entre 2 et 14 participants par atelier.",
          Dash_CRN_H4: "Nombre de personnes",
          Dash_CRN_H5: "Temps approx. requis",
          Dash_CRN_H6: "3 heures",
          Dash_CRN_H7: "Continuer",
          Dash_CRN_H8: "Places disponibles pour nouveau(x) atelier(s):",
          Dash_CRN_H9: "Remarque importante",
          Dash_CRN_H10:
            "L’accès à l’atelier s’ouvre 15 minutes avant l'heure fixée et se ferme 3 heures après l'heure de départ.",
          Dash_CRN_H11: "3. Choisir la langue",
          Dash_CRN_H12: "4. Choisir date et heure",
          Dash_CRN_H13: "2. Nombre de personnes",
          Dash_CRN_H13_0: "1. Type d’atelier",
          Dash_CRN_H14:
            "Choisissez un jour et une plage horaire pour votre atelier.",
          Dash_CRN_H15: "Commande réussie !",
          Dash_CRN_H16: "Créer un nouvel atelier",
          Dash_CRN_H17:
            "Nous vous avons envoyé un courriel contenant deux liens d'accès distincts pour l'atelier (modérateur / participant) ainsi qu'un lien vers le Visual Implicit Profiler (VIP). Vous pouvez dès à présent gérer cet atelier sur le Portail Client (voir Gérer les ateliers existants).",
          Dash_CRN_H17_facilitated:
            "Vous aimeriez avoir un facilitateur qui vous aide à gérer l'atelier à un niveau technique.",
          Dash_CRN_H18:
            "Si vous n'avez pas reçu le courriel, vérifiez d'abord dans votre dossier SPAM. Si vous ne trouvez toujours pas le courriel, contactez-nous par courriel",
          Dash_CRN_H19:
            "Avant de réserver un nouvel atelier, vous devez d'abord acheter un nombre suffisant de licences supplémentaires.",

          modalMissionDetails: {
            missionDetails: "Données de la mission",
            Noof: "Nombre de participants:",
            text1:
              "Le Nom du Team apparaîtra comme titre de votre profil d'équipe unique !",
            timeslotHeader:
              "Modifiez la date et/ou la plage horaire de votre atelier",
            timeslotinfo:
              "Sélectionnez d’abord la date de votre atelier et ensuite le créneau horaire souhaité.",
            changeLanguage: "Changer de langue",
            datetimeunav: "Date ou plage horaire non disponible ?",
            timezone: "Fuseau horaire",
            Isconfirm: "Confirmer",
          },
          modallanguage: {
            languageHeader:
              "Dans quelle langue souhaitez-vous organiser l'atelier ?",
            en: "Anglais",
            de: "Allemand",
            it: "Italien",
            fr: "Français",
            modaltext:
              "Veuillez noter que le choix de la langue de l'atelier détermine également la langue du Visual Implicit Profiler (VIP). Le choix de la langue ne peut être modifié une fois que l’atelier a été confirmés lors de l'étape suivante.",
            mod_italian_unavailable:
              "Malheureusement, les ateliers facilités ne sont pas encore disponibles en italien. SVP sélectionnez un atelier sans facilitateur.",
          },
          modalIWorkshopDeleted: {
            h1: "Workshop Deleted!",
            part1: "You have deleted an existing workshop.",
            part2:
              "The number of Seats allocated to the deleted workshop has now become available again. You may book a new workshop.",
          },
          modalDelete: {
            h1d: "Delete Booking?",
            pd: "Are you sure you would like to delete this booking",
            nokeep: "No Keep",
            yesDelete: "Yes Delete",
          },

          Dash_AC_H1: "Votre compte",
          Dash_AC_H2: "Actualiser les coordonnées",
          Dash_AC_H3: "Coordonnées et histoire d’’achat",
        },
        UpdateForm: {
          UF_GoBack: "Go back",
          UF_MyAccount: "Mon compte",
          UF_BTNTxt: "Histoire d’achats",
          UF_SubTxt: "Actualisez vos coordonnées",
          UF_DelAcc: "Supprimer le compte ?",
          UF_Confm: "Etes-vous sûr de vouloir supprimer ce compte ?",
          UF_Cnf1: "Non - Garder",
          UF_Cnf2: "Oui- Supprimer",
          UF_Acc_Del: "Votre compte a été supprimé !",
          UF_Acc_Del_Cnf: "Vous avez supprimé votre compte.",
          Uf_Acc_Mist:
            "Si le compte a été supprimé par erreur, veuillez nous contacter par courriel sur",
          UF_Call_Txt: "ou appelez-nous au",
          UF_BtnSave: "Enregistrer",
          UF_Lst_Upd: "Denière actualisation",
          UF_Dl_Pf: "Supprimer le compte X",
          PC_1: "Organisation",
          PC_2: "Prénom",
          PC_3: "Nom de famille",
          PC_4: "Numéro de rue",
          PC_5: "Ville",
          PC_6: "Courriel",
          PC_7: "Adresse",
          PC_8: "Code postal",
          PC_9: "",
          PC_10: "",
          PC_11: "",
        },
        ViewAll: {
          VH_goBack: "Go Back",
          VH_upcoming: "À venir",
          VH_past: "Réalisés",
          VH_Ttl: "Gérer les ateliers existants",
          VH_SubTtl:
            "Vous pouvez modifier l atelier à tout moment jusqu à 24 heures avant l heure de départ prévue",
          VH_Wksbkd: "ateliers à venir",
          VH_LSt_H1: "Date de l’atelier",
          VH_LSt_H2: "Heure de l’atelier",
          VH_LSt_H3: "Nombre de personnes",
          VH_LSt_H4: "Nom du team",
          VH_LSt_H5: "Langue",
          VH_LSt_H6: "Modifier la commande",
          VH_Res: "Modifier",
          VH_noData: "aucune donnée trouvée",
        },
        PurchaseHist: {
          PH_goBack: "Retour",
          PH_Ttl: "Histoire d’achats",
          PH_LSt_H1: "Niuméro de commande",
          PH_LSt_H2: "Date de l’achat",
          PH_LSt_H3: "Licences facilitateur",
          PH_LSt_H4: "Licences Participants",
          PH_LSt_H5: "Payé",
          PH_LSt_H6: "Bulletin de commande",
          PH_LSt_H7: "Codes d’accès VIP",
          PH_View: "Regarder",
          PH_Download: "Télécharger",
        },
        thankYouSalesTrue: {
          //h1: "Thank you for contacting our sales team!",
          PurchaseInvoice: {
            h2: "Merci beaucoup pour votre commande !",
            part1:
              "Nous avons envoyé les courriels suivants à votre adresse électronique:",
            part1_a: "1. Le reçu (facture payée) de votre commande.",
            part1_b:
              "2. Une liste de tous les Codes d'Accès VIP que vous avez commandés.",
            part1_c:
              "La ou les licences supplémentaires que vous avez achetées se trouvent désormais dans votre compte sur le Portail Client. Vous pouvez augmenter le nombre de participants à un atelier existant ou créer de nouveaux ateliers.",
            part2:
              "Si vous n'avez pas reçu un ou les deux de ces courriels, vérifiez d'abord dans votre dossier SPAM. Si vous ne trouvez toujours pas les courriels, contactez-nous par courriel",
            email: "booking@mission-team.com",
            part3: "ou appelez-nous au",
            phone: "+41 41 500 04 77",
          },
          BankTransfer: {
            h2: "Merci beaucoup pour votre commande !",
            maintxt:
              "Nous vous avons envoyé une facture à votre adresse électronique. Dès que nous aurons reçu votre paiement, nous vous enverrons tous les documents, liens et codes d'accès dont vous avez besoin pour réussir votre atelier Mission Team.",
            part2:
              "Si vous n'avez pas reçu un ou les deux de ces courriels, vérifiez d'abord dans votre dossier SPAM. Si vous ne trouvez toujours pas les courriels, contactez-nous par courriel",
            cemail: "booking@mission-team.com",
            part3: "ou appelez-nous au",
            phone: "+41 41 500 04 77",
          },
        },
        workshopDetailsPage: {
          workshopHeading: "Atelier",
          resendBookingEmail: "Re-envoyer le courriel de réservation",
          p_links: "Liens pour les participants",
          vip_access_links: "Lien d’accès au VIP",
          vip_access_links_info:
            "Les participants doivent effectuer le VIP avant l’atelier.",
          copy_link: "CoCopier le lien",
          system_check_link: "Lien pour la vérification du système",
          system_check_link_info:
            "Utilisez ce lien pour vérifier la configuration de votre système.",
          vip_access_codes: "Codes d’accès VIP",
          vip_access_codes_view: "Regardez tous vos codes VIP",
          p_access_link: "Lien d’accès à l’atelier pour les participants",
          p_links_info:
            "Tous les modèles de courriel et les détails de l'atelier peuvent être consultés dans le courriel de réservation.",
          m_links: "Lien pour co-modérateur",
          m_training_link: "Lien pour la formation de modérateur",
          m_training_link_info: "Apprenez à faciliter un atelier ici.",
          m_workshop_access_link: "Lien d’accès à l’atelier pour le modérateur",
          m_workshop_access_link_info:
            "Utlisez ce lien pour accéder à l’atelier comme modérateur.",
          m_checklist: "Check-liste de modération",
          m_checklist_download: "Télécharger",
          m_training_link_link:
            "https://app.spatial.chat/s/MissionTeam-ModeratorTraining_FR1",
          m_checklist_link:
            "https://drive.google.com/file/d/12quMDZZg1KpNNOyiR8SRzpp_gK_LbnYi/view?usp=sharing",
          past_teamprofile_download: "Télécharger",
          past_participants_heading: "Participants",
          past_participants_info: "Tous les participants de l’atelier",
          past_mod: "Co-modérateur / Facilitateur",
          past_mod_info: "Peut avoir été participant ou non",
          past_mod_button: "Co-Moderator*in",
        },
      },
      it: {
        LoginPage: {
          Login_h1_1: "Gestire i miei ordini",
          Login_h1_2: "Mission Team Digital Teambuilding",
          Login_SubTxt:
            "Inserisca il suo codice d'identificazione per accedere al portale clienti.",
          Login_Code_WtMrk: "Inserisca il codice",
          Login_Code_BTntxt: "Continuare",
          Login_forgtlnk_1: "Ha dimenticato il suo codice d' identificazione?",
          Login_forgtlnk_2: "Clicchi qui",
          Login_Frgtbx: "Richieda un nuovo codice",
          Login_Frgtbx_subtxt1:
            "Inserisca il suo indirizzo e-mail e le invieremo un nuovo codice d’identificazione.",
          Login_Frgtbx_subtxt2: "Nuovo codice",
          Login_Frgtbx_subtxt3: "Ha bisogno di maggiore supporto?",
          Login_Frgtbx_subtxt4: "Il suo codice è stato inviato!",
          Login_Frgtbx_subtxt5:
            "Il suo nuovo codice d'identificazione è stato inviato.",
          Login_Frgtbx_subtxt6:
            "Se non ha ricevuto l’e-mail, controlli prima la sua cartella SPAM. Se non riesce ancora a trovare l’e-mail, ci contatti via e-mail",
          Login_Frgtbx_subtxt7: "o chiamateci al",
        },
        Dashboard: {
          Dash_MainH1: "Gestire i workshop esistenti",
          Dash_Viewall: "Mostrare tutti",
          Dash_Yes: "sì",
          Dash_No: "No",
          Dash_MB_H1: "workshop in arrivo",
          Dash_MB_1: "Data del workshop",
          Dash_MB_2: "Orario del workshop",
          Dash_MB_3: "Numero de persone",
          Dash_MB_3_1: "Facilitatore",
          Dash_MB_4: "Nome del team",
          Dash_MB_5: "Lingua",
          Dash_MB_6: "Modificare l'ordine",
          Dash_MB_6_1: "Info",
          Dash_MB_7:
            "Se si ripianifica un workshop facilitato, la licenza di facilitatore andrà persa. Tuttavia, può modificare la prenotazione e riprogrammare il workshop in qualsiasi momento fino a 24 ore prima del workshop senza perdere le licenze dei partecipanti.",
          Dash_MB_8: "Numero di partecipanti aggiornato!",
          Dash_MB_9:
            "Se ha aggiunto un partecipante, non dimentichi di inviare al nuovo partecipante un codice di accesso VIP con il link corrispondente e il link per accedere al workshop.",
          Dash_MB_10:
            "Se ha cancellato un partecipante, può inviare il codice di accesso VIP inutilizzato a un altro partecipante per quel workshop o per un nuovo workshop.",
          Dash_MB_11: "Workshop cancellato!",
          Dash_MB_12: "Ha cancellato un workshop ordinato.",
          Dash_MB_13:
            "Il numero di posti assegnati al workshop cancellato è di nuovo disponibile. Può prenotare un nuovo workshop.",
          Dash_MB_14: "Cancellare il workshop?",
          Dash_MB_15: "È sicuro di voler cancellare questo workshop?",
          Dash_MB_16: "No - Mantenere",
          Dash_MB_17: "Si - Cancellare",
          Dash_MB_18: "Modifica la data e/o l'orario del suo workshop",
          Dash_MB_19: "Aggiornamento della data e/o dell'ora del workshop!",
          Dash_MB_20:
            "La preghiamo di notare che i due link (partecipanti/moderatore) che ha già ricevuto per accedere al workshop non funzioneranno più.",
          Dash_MB_21:
            "Le abbiamo inviato nuavamento l'e-mail con tutte le informazioni necessarie per i partecipanti e il moderatore. La preghiamo di notare che solo i due link di accesso al workshop (partecipante/moderatore) sono nuovi. Il resto delle informazioni non è cambiato e le è già stato inviato una volta.",
          Dash_MB_20_F:
            "La preghiamo di notare che il link che ha già ricevuto per consentire ai partecipanti di accedere al workshop non funzionerà più.",
          Dash_MB_21_F:
            "Le abbiamo inviato nuovamente l'e-mail con tutte le informazioni necessarie per i partecipanti. La preghiamo di notare che solo il link di accesso al workshop (partecipanti) è nuovo. Il resto delle informazioni non è cambiato e le è già stato inviato una volta.",
          Dash_MB_22: "La data o la fascia oraria non sono disponibili?",
          Dash_MB_23:
            "Ci dispiace! Mission Team Digital Teambuilding è molto richiesto e purtroppo la data e/o la fascia oraria da lei scelta non sono attualmente disponibili per un workshop.",
          Dash_MB_24:
            "Tuttavia, faremo del nostro meglio per accogliere la sua richiesta. Ci contatti per maggiori informazioni  via e-mail",
          Dash_MB_25: "o ci chiami al",

          Dash_MB_26: "Modificare",
          Dash_MB_27: "Email inviata",
          Dash_MB_28: "I dettagli del workshop sono stati inviati a",
          Dash_MB_29: "Chiudere",
          Dash_MB_30: "Stato",
          Dash_MB_31: "Scaricare il profilo del team",

          Dash_MB_32: "Si prega di acquistare licenze aggiuntive",
          Dash_MB_33:
            "È necessario acquistare licenze aggiuntive per continuare.",
          Dash_MB_34: "Acquistare",
          Dash_MB_35: "Più tardi",

          Dash_MB_36: "Ripianifica?",
          Dash_MB_37:
            "Se si ripianifica un workshop facilitato, la licenza di facilitatore andrà persa. Pertanto, può riprogrammare un workshop solo se sul suo conto è disponibile una licenza di facilitatore. Acquisti prima una nuova licenza di facitore, se non ne ha più una sul suo conto.",
          Dash_MB_38: "Acquistare una licenza di facilitatore",
          Dash_MB_39: "Ripianificare",

          Dash_AVB_H1: "Licenze",
          Dash_AVB_1: "Licenze dei partecipanti disponibili",
          Dash_AVB_1_1: "Licenze dei facilitatori disponibili",
          Dash_AVB_2: "Acquistare licenze aggiuntive",
          Dash_AVB_3:
            "Prima di ordinare un nuovo workshop, potrebbe essere nec essario acquistare delle licenze aggiuntive. Ogni licenza include un codice di accesso a Visual Implicit Profiler (VIP) e un posto nel workshop digitale. Il numero di licenze disponibili si basa sul numero di persone assegnate ai workshop, sia passati che futuri.",
          Dash_AVB_4: "1. Calcola Quite",
          Dash_AVB_5: "2. Riepilogo ordine",
          Dash_AVB_6: "3. Pagamento",
          Dash_AVB_7_DC_1:
            "Il nostro calcolatore dinamico calcola la sua offerta.",
          Dash_AVB_8_DC_2: "Licenze di partecipanti",
          Dash_AVB_8_DC_2_1: "Licenze di facilitatore",
          Dash_AVB_8_DC_3: "Costo / Licenze*",
          Dash_AVB_8_DC_4: "Costo totale",
          Dash_AVB_8_DC_5: "Confirmare",
          Dash_AVB_8_DC_6: "* piu IVA si applicabile (solo in Svizzera)",
          Dash_AVB_8_DC_7: "* L'IVA del 7,7% si applica solo in Svizzera.",
          TotalCost: "Costi totali",

          Dash_AVB_9_OS_1: "Conferma dell'ordine",
          Dash_AVB_9_OS_2: "Confermi o modifichi il suo ordine",
          Dash_AVB_9_OS_3: "Tipo di pagamento : ",
          Dash_AVB_9_OS_4: "Carta",
          Dash_AVB_9_OS_5: "Bonifico bancario",
          Dash_AVB_9_OS_6: "ID dell'ordine",
          Dash_AVB_9_OS_7: "Nome del prodotto",
          Dash_AVB_9_OS_8: "Numero totale di licenze",
          Dash_AVB_9_OS_9: "Prezzo per unità",
          Dash_AVB_9_OS_10: "Totale",
          Dash_AVB_9_OS_11: "Licenze:",
          Dash_AVB_9_OS_12: "* L'IVA del 7,7% \nsi applica solo in Svizzera.",
          Dash_AVB_9_OS_13: "Costi del prodotto:",
          Dash_AVB_9_OS_14: "IVA* (7,7 %):",
          Dash_AVB_9_OS_15: "Costi totali",
          Dash_AVB_9_OS_16: "Proseguire con il pagamento",
          Dash_AVB_9_OS_17:
            "Accetto i Termini e Condizioni generali di PSYfiers",

          Dash_AVB_10_THK_1: "Grazie mille per il suo ordine!",
          Dash_AVB_10_THK_2: "Le abbiamo inviato le seguenti e-mail:",
          Dash_AVB_10_THK_3: "1.La ricevuta (fattura pagata) del suo ordine.",
          Dash_AVB_10_THK_4:
            "2.Un elenco di tutti i codici di accesso VIP che ha ordinato.",
          Dash_AVB_10_THK_5:
            "Le licenze aggiuntive che ha acquistato sono ora nel suo account sul portale clienti. Può aumentare il numero di partecipanti a un workshop esistente o creare nuovi workshop.",
          Dash_AVB_10_THK_6:
            "Se non ha ricevuto una o entrambe le e-mail, controlli innanzitutto la cartella SPAM. Se non riesce a trovare le e-mail, ci contatti all'indirizzo e-mail",
          Dash_AVB_10_THK_7: "o ci chiami al numero",

          workshopTypeModal: {
            h1: "Selezioni il tipo di workshop che desidera acquistare.",
            self: "Workshop auto-moderato",
            self_desc:
              "Lei nominerà il suo co-moderatore per facilitare il workshop.",
            faciliated: "Workshop facilitato",
            faciliated_desc:
              "Desidera avere un facilitatore che la aiuti a gestire il workshop a livello tecnico.",
          },
          Dash_CRN_H1: "Creare un nuovo workshop",
          Dash_CRN_H2: "Creare un workshop Mission Team",
          Dash_CRN_H3:
            "Raccomandiamo di organizzare ogni workshop con 4-12 partecipanti. Tuttavia, è possibile allargare il numero di partecipanti da 2 a 14.",
          Dash_CRN_H4: "Numero de persone",
          Dash_CRN_H5: "Tempo indicativo richiesto",
          Dash_CRN_H6: "3 ore",
          Dash_CRN_H7: "Continuare",
          Dash_CRN_H8: "Posti disponibili per nuovi workshop:",
          Dash_CRN_H9: "Nota importante",
          Dash_CRN_H10:
            "L'accesso al workshop si apre 15 minuti prima dell'orario previsto e si chiude 3 ore dopo la partenza.",
          Dash_CRN_H11: "3. Choisir la langue",
          Dash_CRN_H12: "4. Choisir date et heure",
          Dash_CRN_H13: "2. Nombre de personnes",
          Dash_CRN_H13_0: "1. Tipo di workshop",
          Dash_CRN_H14:
            "Scelga un giorno e una fascia oraria per il suo workshop.",
          Dash_CRN_H15: "Ordine andato a buon fine!",
          Dash_CRN_H16: "Creare un nuovo workshop",
          Dash_CRN_H17:
            "Le abbiamo inviato un'e-mail con due link di accesso separati per il workshop (moderatore/partecipante) e un link al Visual Implicit Profiler (VIP). Ora può gestire questo workshop sul portale clienti (vedere Gestire i workshop esistenti).",
          Dash_CRN_H17_facilitated:
            "Desidera avere un facilitatore che la aiuti a gestire il workshop a livello tecnico.",
          Dash_CRN_H18:
            "Se non ha ricevuto l’e-mail, controlli prima la sua cartella SPAM. Se non riesce ancora a trovare l’e-mail, ci contatti via e-mail",
          Dash_CRN_H19:
            "Prima di prenotare un nuovo workshop, deve prima acquistare un numero sufficiente di licenze aggiuntive.",
          modalMissionDetails: {
            missionDetails: "Dati della missione",
            Noof: "Numero di partecipanti:",
            text1:
              "Il nome del team apparirà come titolo del suo profilo di team su misura!",
            timeslotHeader: "Modifichi la data e/o l'orario del suo workshop",
            timeslotinfo:
              "Selezioni prima la data del suo workshop e poi la fascia oraria desiderata.",
            changeLanguage: "Cambiare la lingua",
            datetimeunav: "Data o fascia oraria non disponibile?",
            timezone: "Fuso orario",
            Isconfirm: "Confermare",
          },
          modallanguage: {
            languageHeader: "In quale lingua desidera tenere il workshop?",
            en: "Inglese",
            de: "Tedesco",
            it: "Italiano",
            fr: "Francese",
            modaltext:
              "Si prega di notare che la scelta della lingua per il workshop determina anche la lingua del Visual Implicit Profiler (VIP). La scelta della lingua non può essere modificata una volta che il workshop è stato confermato nella fase successiva.",
            mod_italian_unavailable:
              "Purtroppo, i workshop facilitati non sono ancora disponibili in italiano. La preghiamo di selezionare un workshop senza facilitatore.",
          },
          modalIWorkshopDeleted: {
            h1: "Workshop Deleted!",
            part1: "You have deleted an existing workshop.",
            part2:
              "The number of Seats allocated to the deleted workshop has now become available again. You may book a new workshop.",
          },
          modalDelete: {
            h1d: "Delete Booking?",
            pd: "Are you sure you would like to delete this booking",
            nokeep: "No Keep",
            yesDelete: "Yes Delete",
          },

          Dash_AC_H1: "Il suo account",
          Dash_AC_H2: "Aggiornare dettagli di contatto",
          Dash_AC_H3: "Coordonnées et histoire d’’achat",
        },
        UpdateForm: {
          UF_GoBack: "Go back",
          UF_MyAccount: "Il mio account",
          UF_BTNTxt: "Storia degli acquisti",
          UF_SubTxt: "Aggiornare i suoi dettagli di contatto",
          UF_DelAcc: "Cancellare l'account?",
          UF_Confm: "È sicuro di voler cancellare questo account?",
          UF_Cnf1: "No - Mantenere",
          UF_Cnf2: "Si- Cancellare",
          UF_Acc_Del: "Il suo account è stato cancellato!",
          UF_Acc_Del_Cnf: "Ha cancellato il suo account. ",
          Uf_Acc_Mist:
            "Se l account è stato cancellato per errore, la preghiamo di contattarci via e-mail all indirizzo",
          UF_Call_Txt: "o di telefonarci al numero",
          UF_BtnSave: "Registrare",
          UF_Lst_Upd: "Ultimo aggiornamento",
          UF_Dl_Pf: "Cancellare il account X",
          PC_1: "Organisation",
          PC_2: "Nome",
          PC_3: "Cognome",
          PC_4: "Numero civico",
          PC_5: "Citta",
          PC_6: "E-mail",
          PC_7: "Indirizzo",
          PC_8: "Codice postale",
          PC_9: "",
          PC_10: "",
          PC_11: "",
        },
        ViewAll: {
          VH_goBack: "Go Back",
          VH_upcoming: "In arrivo",
          VH_past: "Realizzato",
          VH_Ttl: "Gestire i workshop esistenti",
          VH_SubTtl:
            "Può cambiare il workshop in qualsiasi momento fino a 24 ore prima dell orario di inizio previsto",
          VH_Wksbkd: "workshop in arrivo",
          VH_LSt_H1: "Data del workshop",
          VH_LSt_H2: "Orario del workshop",
          VH_LSt_H3: "Numero di persone",
          VH_LSt_H4: "Nome del team",
          VH_LSt_H5: "Lingua",
          VH_LSt_H6: "Modificare l ordine",
          VH_Res: "Modificare",
          VH_noData: "nessun dato trovato",
        },
        PurchaseHist: {
          PH_goBack: "Retour",
          PH_Ttl: "Storia degli acquisti",
          PH_LSt_H1: "Numero d ordine",
          PH_LSt_H2: "Data dell acquisto",
          PH_LSt_H3: "Licenze facilitatore",
          PH_LSt_H4: "Licenze partecipanti",
          PH_LSt_H5: "Pagato",
          PH_LSt_H6: "Modulo d ordine",
          PH_LSt_H7: "Codici di accesso VIP",
          PH_View: "Visualizzare",
          PH_Download: "Scaricare",
        },
        thankYouSalesTrue: {
          // h1: "Thank you for contacting our sales team!",
          PurchaseInvoice: {
            h2: "Grazie mille per il suo ordine!",
            part1: "Le abbiamo inviato le seguenti e-mail:",
            part1_a: "1. La ricevuta (fattura pagata) del suo ordine.",
            part1_b:
              "2. Un elenco di tutti i codici di accesso VIP che ha ordinato.",
            part1_c:
              "Le licenze aggiuntive che ha acquistato sono ora nel suo account sul portale clienti. Può aumentare il numero di partecipanti a un workshop esistente o creare nuovi workshop.",
            part2:
              "Se non ha ricevuto una o entrambe le e-mail, controlli innanzitutto la cartella SPAM. Se non riesce a trovare le e-mail, ci contatti all'indirizzo e-mail",
            email: "booking@mission-team.com",
            part3: "o ci chiami al numero",
            phone: "+41 41 500 04 77",
          },
          BankTransfer: {
            h2: "Grazie mille per il suo ordine! ",
            maintxt:
              "Le abbiamo inviato una fattura al suo indirizzo e-mail. Non appena riceveremo il pagamento, le invieremo tutti i documenti, i link e i codici di accesso necessari per completare con successo il workshop Mission Team.",
            part2:
              "Se non ha ricevuto l’e-mail, controlli prima la sua cartella SPAM. Se non riesce ancora a trovare l’e-mail, ci contatti via e-mail",
            cemail: "booking@mission-team.com",
            part3: "o ci chiami al numero",
            phone: "+41 41 500 04 77",
          },
        },
        workshopDetailsPage: {
          workshopHeading: "Workshop",
          resendBookingEmail: "Reinvio dell'e-mail di prenotazione",
          p_links: "Link per i partecipanti",
          vip_access_links: "Link di accesso per il VIP",
          vip_access_links_info:
            "I partecipanti devono completare il VIP prima del workshop.",
          copy_link: "Copiare il link",
          system_check_link: "Link al controllo del sistema",
          system_check_link_info:
            "Utilizzi questo link per verificare la configurazione del suo sistema.",
          vip_access_codes: "Codici di accesso VIP",
          vip_access_codes_view: "Visualizzi tutti i suoi codici VIP",
          p_access_link: "Link al workshop per i partecipanti",
          p_links_info:
            "Tutti i template delle e-mail e i dettagli del workshop si trovano nell'e-mail di prenotazione.",
          m_links: "Link per i moderatori",
          m_training_link: "Link alla formazione dei moderatori",
          m_training_link_info: "Impari a facilitare un workshop qui.",
          m_workshop_access_link: "Link al workshop per il moderatore",
          m_workshop_access_link_info:
            "Utilizzi questo link per accedere al workshop come moderatore.",
          m_checklist: "Checklist di moderazione",
          m_checklist_download: "Scaricare",
          m_training_link_link:
            "https://app.spatial.chat/s/MissionTeam-ModeratorTraining_IT1",
          m_checklist_link:
            "https://drive.google.com/file/d/1pKnn3ti9dYfW3H9IMiIF8zUjv7wNTFF0/view?usp=sharing",
          past_teamprofile_download: "Scaricare",
          past_participants_heading: "Partecipanti",
          past_participants_info: "Tutti i partecipanti al workshop",
          past_mod: "Co-Moderatore / Facilitatore",
          past_mod_info: "Può essere o non essere stato un partecipantenot",
          past_mod_button: "Co-Moderator*in",
        },
      },
    },
  });
  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      {props.children}
    </LanguageContext.Provider>
  );
};
