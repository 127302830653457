import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/app.context";
import {
  getLocalStorage,
  setLocalStorage,
  formatCurrencyIntoEUR,
} from "../../utils/common";
import { baseApiUrl } from "../../utils/base";
import defaultValues from "../../utils/data/defaultValues.json";
import { LanguageContext } from "../../context/language.context";
import "./Ordersummary.css";

const OrderSummary = ({ openModalpayment }) => {
  const [language] = useContext(LanguageContext);
  const usedLanguage = language.translations[language.selectedLanguage];

  const {
    Dash_AVB_9_OS_3,
    Dash_AVB_9_OS_4,
    Dash_AVB_9_OS_5,
    Dash_AVB_9_OS_12,
    Dash_AVB_9_OS_14,
    Dash_AVB_9_OS_16,
    Dash_AVB_9_OS_17,
    TotalCost,
  } = usedLanguage.Dashboard;

  const [apiResponse, setApiResponse] = useState();
  const [appState] = useContext(AppContext);
  const [cardType, setcardType] = useState("card");
  const [checkConsent, setcheckConsent] = useState(false);
  const [proceedClicked, setProceedClicked] = useState(false);
  const [prices, setPrices] = useState({ p: 0, f: 0 });
  const [totals, setTotals] = useState({ p: 0, f: 0 });
  const [vat, setVat] = useState(0);

  const handleChangecheck = (event) => {
    if (event.target.checked) {
      console.log("✅ Checkbox is checked");
    } else {
      console.log("⛔️ Checkbox is NOT checked");
    }
    setcheckConsent((current) => !current);
  };
  const mandatoryFieldStyle = () => {
    var s = checkConsent;
    return {
      color: !checkConsent ? "red !important" : "#9e9d9c !important",
    };
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleChange = (e) => {
    setIsChecked(!isChecked);
    const { value } = e.target;
    setcardType(value);
  };

  const fnPayment = () => {
    const { liceneceCount, facilitatorCount } = appState;

    if (checkConsent === true) {
      const url_order_id = baseApiUrl + `order_id`;
      fetch(url_order_id)
        .then((response) => response.json())
        .then((data) => {
          if (data.code === 200) {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            var authDetails = getLocalStorage("authDetailsLocal");
            var userData = getLocalStorage("userDataLocal");
            const url = baseApiUrl + "client/order";

            var orderData = {
              customerId: authDetails.userId,
              orderId: `MTDT${data.data}`,
              productCode: "MT001",
              totalQty: liceneceCount,
              unitPrice: prices.p,
              totalPrice: totals.p,
              vatAmount: vat,
              vatPercent: 7.7,
              totalCost: totals.p + totals.f + vat,
              paymentType: cardType,
              country: userData.country,
              currency: "EUR",
              cet: timeZone,
              items: [
                {
                  productCode: "MT002",
                  totalQty: facilitatorCount,
                  unitPrice: prices.f,
                  vatAmount: vat,
                  vatPercent: 7.7,
                  totalPrice: totals.f,
                },
              ],
            };
            console.log(orderData);

            fetch(url, {
              method: "POST",
              mode: "cors",
              body: JSON.stringify(orderData),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authDetails.token}`,
              },
            })
              .then(function (promise) {
                return promise.json();
              })
              .then(function (response) {
                if (response.code === 200) {
                  setLocalStorage(
                    defaultValues.orderCalculation,
                    "orderCalculatorLocale"
                  );
                  setLocalStorage(0, "toggleOrderId");
                  window.location.href = response.data.billUrl;
                } else if (response.code !== 200) {
                  setApiResponse(response.message);
                }
              })
              .catch((error) => {
                setApiResponse(error);
                console.log(error);
              });
          } else {
            alert("Order ID Failed. Try again");
          }
        });

      return;
    } else {
      setcheckConsent(false);
    }
  };

  useEffect(() => {
    if (
      appState.unitPriceFacilitator !== undefined &&
      appState.unitPriceParticipant !== undefined
    ) {
      setPrices({
        p: appState.unitPriceParticipant,
        f: appState.unitPriceFacilitator,
      });
    }
  }, [appState.unitPriceFacilitator, appState.unitPriceParticipant]);

  useEffect(() => {
    setTotals({
      p: appState.liceneceCount * prices.p,
      f: appState.facilitatorCount * prices.f,
    });
  }, [prices, appState.liceneceCount, appState.facilitatorCount]);

  useEffect(() => {
    try {
      const userData = getLocalStorage("userDataLocal");
      const UserCountry = userData.country.toLowerCase();
      if (["switzerland", "ch"].includes(UserCountry)) {
        const tempTotal = totals.p + totals.f;
        const vatAmount = tempTotal * 0.077;
        setVat(vatAmount);
      }
    } catch (error) {
      setVat(0);
    }
  }, [totals]);

  return (
    <div>
      <div className="container">
        <div className="table-responsive">
          <table className="three_column_table">
            <tbody>
              <tr className="table-lable">
                <td>
                  {Dash_AVB_9_OS_12.split("\n").map((text) => {
                    return (
                      <p key={text} style={{ marginBottom: "0px" }}>
                        {text}
                      </p>
                    );
                  })}
                </td>
                <td style={{ textAlign: "center" }}>
                  <span className="black">
                    {formatCurrencyIntoEUR(vat)} EUR
                  </span>
                  <p>{Dash_AVB_9_OS_14}</p>
                </td>
                <td style={{ textAlign: "right" }}>
                  <span className="black">
                    {formatCurrencyIntoEUR(totals.p + totals.f + vat)} EUR
                  </span>
                  <br />
                  {TotalCost}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="dynamic_cal_footer">
          <div className="left_cal">
            <div className="checkbox-row">
              <label className="page_sub_heading">{Dash_AVB_9_OS_3}</label>
              <div className={`checkbox-block`}>
                <input
                  id="Card"
                  type="checkbox"
                  name="consent"
                  value="card"
                  onChange={handleChange}
                  checked={!isChecked}
                />
                <label htmlFor="Card">{Dash_AVB_9_OS_4}</label>
              </div>
              <div className={`checkbox-block`}>
                <input
                  id="Bank"
                  type="checkbox"
                  name="consent"
                  value="bank_transfer"
                  onChange={handleChange}
                  checked={isChecked}
                />
                <label htmlFor="Bank">{Dash_AVB_9_OS_5}</label>
              </div>
            </div>
            {/* consent */}
            <div className="checkbox-block">
              <div
                className={`checkbox-block ${
                  proceedClicked && !checkConsent
                    ? "order-confirm-not-checked"
                    : "order-confirm-checked"
                }`}
              >
                <input
                  id="Business"
                  type="checkbox"
                  style={mandatoryFieldStyle()}
                  onChange={(e) => {
                    handleChangecheck(e);
                  }}
                  checked={checkConsent}
                ></input>
                <label htmlFor="Business">{Dash_AVB_9_OS_17} </label>
              </div>
            </div>
          </div>
          <div className="right_cal">
            <button
              onClick={() => {
                setProceedClicked(true);
                if (checkConsent === true) {
                  fnPayment();
                }
              }}
              className="btnproceedpay"
            >
              {Dash_AVB_9_OS_16}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderSummary;
