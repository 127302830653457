import React, { useEffect, useContext } from "react";
import DashboardNav from "../../components/DashboardHeaderFooter/DashboardNav";

import ManageBooking from "../../components/Dashboard/ManageBooking";
import AvailableLicenses from "../../components/Dashboard/AvailableLicenses";
import CreateNewWorkshops from "../../components/Dashboard/CreateNewWorkshops";
import Footer from "../../components/Footer/Footer";
import Business from "../../components/Dashboard/Business";
import { Link } from "react-router-dom";
import "./Dashboard.css";
import { AppContext } from "../../context/app.context";
import { getLocalStorage } from "../../utils/common";
import { LanguageContext } from "../../context/language.context";

// import CountrySelect from '../components/CreateNewWorkshopCountrySelect';

const Dashboard = () => {
  const [appState, setAppState] = useContext(AppContext);
  const [language] = useContext(LanguageContext);
  const usedLanguage = language.translations[language.selectedLanguage];

  const sellang = language.selectedLanguage;
  if (sellang === "") {
    sellang = "en";
  }

  const { Dash_MainH1, Dash_Viewall } = usedLanguage.Dashboard;

  const redirectlink = "/view?lang=" + sellang;
  useEffect(() => {
    var Temp = getLocalStorage("licLocal");
    var templicenseCalculator = {
      total: Temp.total,
      available: Temp.available,
      totalFacilitators: Temp.totalFacilitators,
      availableFacilitators: Temp.availableFacilitators,
    };
    setAppState({
      ...appState,
      licenseCalculator: templicenseCalculator,
    });
  }, []);
  return (
    <div>
      <div className="div-Dashboard home-page">
        <section>
          <DashboardNav />
        </section>
        <section className="manageExisting-header">
          <div className="container" style={{ marginTop: "70px" }}>
            <div className="row align-items-center">
              <div className="col-lg-8">
                <span className="managebookingtext">{Dash_MainH1}</span>
              </div>
              <div className="col-lg-4 alignright">
                <div className="view-block">
                  <Link to={redirectlink} className="viewall">
                    {Dash_Viewall}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ManageBooking />
        <AvailableLicenses />
        <CreateNewWorkshops />
        <Business />
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
