import React, { useContext, useState, useEffect } from "react";
import "./PickDateTime.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { baseApiUrl } from "../../utils/base";
import { baseApiUrlbooking } from "../../utils/base";
import Modal from "react-modal";

import {
  getTimeSlots,
  FieldMandatoryView,
  setLocalStorage,
  getFormatedDate,
} from "../../utils/common";
import {
  EnglishIconSvg,
  FrenchIconSvg,
  GermanIconSvg,
  ItalianIconSvg,
} from "../languagedsvg/langsvg";
import { getLocalStorage } from "../../utils/common";
import workshop from "../../models/workshop";
import { LanguageContext } from "../../context/language.context";
import { AppContext } from "../../context/app.context";

Modal.setAppElement("#root");

const workshopTimeBlocks = 6;

const PickDateTime = (props) => {
  const [appState, setAppState] = useContext(AppContext);
  const [language] = useContext(LanguageContext);
  const usedLanguage = language.translations[language.selectedLanguage];
  const { modalMissionDetails } = usedLanguage.Dashboard;

  const {
    missionDetails,
    Noof,
    text1,
    timeslotHeader,
    timeslotinfo,
    datetimeunav,
    timezone,
    Isconfirm,
    changeLanguage,
  } = modalMissionDetails;

  console.log(props);

  const startDate = new Date();

  const [selectedDate, setSelectedDate] = useState();

  const [teamName, setTeamName] = useState("");
  const [showTeamNameError, setShowTeamNameError] = useState("");
  const [people, setPeople] = useState(8);

  const [workshops, setWorkshops] = useState([]);

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [timeSlots, setTimeSlots] = useState(getTimeSlots());

  const setStorageLicenseCount = (totalLicenses) => {
    localStorage.setItem("totalLicenses", totalLicenses);
  };
  const updateLicenseCountOnInit = (storageWorkshops) => {
    let totalLicenses = 0;
    for (let i = 0; i < storageWorkshops.length; i++) {
      totalLicenses += storageWorkshops[i].personInGroup;
    }
    setStorageLicenseCount(totalLicenses);
  };

  const LoadInitials = () => {
    if (!props.IsRechedule) {
      setSelectedDate(new Date());
      setPeople(props.PersonInGroupCount);
      updateLicenseCountOnInit(workshops);
    } else {
      setTeamName(props.TeamName);
      setPeople(props.PersonInGroupCount);
      setSelectedDate(props.SelectedDate);
      updateLicenseCountOnInit(workshops);
    }
  };

  const clearValues = () => {
    setTeamName("");
    setShowTeamNameError(false);
    setPeople(8);
    setSelectedDate(new Date());
  };

  const onDateChange = (date) => {
    setSelectedDate(date);
  };

  const updateTeamName = (value) => {
    setShowTeamNameError(value === "");
    setTeamName(value);
  };

  const selectTimeSlot = (index) => {
    const tempTimeSlots = [...timeSlots];
    let timeSlot = tempTimeSlots.at(index);

    if (timeSlot.isBooked) return;
    if (!timeSlot.isStartOfWorkshop) {
      if (index + workshopTimeBlocks > tempTimeSlots.length) return;
      resetTimeSlots();

      tempTimeSlots[index].isFirstClick = true;
      tempTimeSlots[index].isStartOfWorkshop = true;
      for (let i = index + 1; i <= index + workshopTimeBlocks; i++) {
        tempTimeSlots[i].isFirstClick = true;
      }
      setTimeSlots(tempTimeSlots);
    } else if (timeSlot.isStartOfWorkshop) {
      if (teamName === "") {
        setShowTeamNameError(true);
        return;
      }
      addWorkshop(index);
    }
  };

  // reset time slots if selected another start time.
  const resetTimeSlots = () => {
    let temp = [...timeSlots];
    for (let i = 0; i < temp.length; i++) {
      temp[i].isStartOfWorkshop = false;
      temp[i].isFirstClick = false;
    }
    setTimeSlots(temp);
  };

  const addWorkshop = (timeSlotStartIndex) => {
    const startTime = timeSlots[timeSlotStartIndex].time.toLowerCase();
    const endTime = timeSlots[timeSlotStartIndex + 6].time.toLowerCase();
    const newWorkshop = new workshop();
    newWorkshop.workshopId = props.IsRechedule
      ? props.WorkshopId
      : new Date().getTime().toString();
    newWorkshop.workshopDate = getFormatedDate(selectedDate);
    newWorkshop.workshopTime = startTime + " - " + endTime;
    newWorkshop.personInGroup = people;
    newWorkshop.teamName = teamName;
    newWorkshop.language = props.languageCode;
    newWorkshop.facilitated = !props.self_mod;
    workshops.push(newWorkshop);
    setWorkshops(workshops);
    if (!props.IsRechedule) {
      updateLicenseCount();
    }
    localStorage.setItem("workshops", JSON.stringify(workshops));
    props.IsRechedule ? pushWorkgroupRechdule() : pushWorkgroup();
    //pushWorkgroup();
    clearValues();
  };

  const pushWorkgroupRechdule = () => {
    openLoader();
    if (workshops.length === 0) {
      closeLoader();
      return;
    }
    //const postWorkshops = [];
    const auth = getLocalStorage("authDetailsLocal");
    const workshop = workshops[0];
    const date = workshop.workshopDate.split("-");
    const postWorkshop = {
      customerId: auth.userId,
      bookingId: props.BookingId,
      workshopId: workshop.workshopId,
      workshopDate: date[2] + "-" + date[1] + "-" + date[0],
      workshopTime: workshop.workshopTime.split("-")[0].trim(),
    };

    console.log(postWorkshop);
    const url = baseApiUrl + "client/workshop/reschedule";

    fetch(url, {
      body: JSON.stringify(postWorkshop),
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then(function (promise) {
        return promise.json();
      })
      .then(function (response) {
        console.log(response);
        if (response.code === 200) {
          loadBookingDetails();
          props.openModalSuccessfullybooked();

          localStorage.removeItem("workshops");
        } else {
          closeLoader();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadBookingDetails = (authDetails) => {
    var getAuthDetails = getLocalStorage("authDetailsLocal");
    if (getAuthDetails === null) getAuthDetails = authDetails;
    const apiUrlBooking = baseApiUrl + "client/booking/";

    fetch(apiUrlBooking + getAuthDetails.userId, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getAuthDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        console.log(response);
        if (response.code === 200) {
          setLocalStorage(response.data.bookingList, "bookingListLocal");
          setLocalStorage(response.data.userData, "userDataLocal");
          var templicenseCalculator = {
            total: response.data.userData.totalLicenses,
            available: response.data.userData.availableLicenses,
            totalFacilitators: response.data.userData.totalFacilitators,
            availableFacilitators: response.data.userData.availableFacilitators,
          };
          setLocalStorage(templicenseCalculator, "licLocal");
          setAppState({
            ...appState,
            licenseCalculator: templicenseCalculator,
          });
          //updateLicenseCount();
        } else {
          console.log(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pushWorkgroup = () => {
    openLoader();
    if (workshops.length === 0) {
      closeLoader();
      return;
    }

    const auth = getLocalStorage("authDetailsLocal");
    const workshop = workshops[0];
    const date = workshop.workshopDate.split("-");

    let utcdiff = new Date().getTimezoneOffset();
    let utcinhr = utcdiff / 60;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const postWorkshop = {
      customerId: auth.userId,
      workshopId: workshop.workshopId,
      workshopDate: date[2] + "-" + date[1] + "-" + date[0],
      workshopTime: workshop.workshopTime.split("-")[0].trim(),
      teamName: workshop.teamName,
      personInGroup: workshop.personInGroup,
      language: workshop.language.toLowerCase(), //getLanguageByCode(workshop.language.toLowerCase()),
      cet: timeZone,
      timezonediff: utcinhr.toString(),
      facilitated: workshop.facilitated,
    };

    const url = baseApiUrl + "client/workshop/";

    fetch(url, {
      body: JSON.stringify(postWorkshop),
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then(function (promise) {
        return promise.json();
      })
      .then(function (response) {
        console.log(response);
        if (response.code === 200) {
          loadBookingDetails();
          // updateLicenseCountOnInit();
          //updateLicenseCount();
          props.openModalSuccessfullybooked();

          localStorage.removeItem("workshops");
        }
        closeLoader();
      })
      .catch((error) => {
        console.log(error);
        closeLoader();
      });
  };

  const openLoader = () => {
    setAppState({ ...appState, loader: true });
  };

  const closeLoader = () => {
    setAppState({ ...appState, loader: false });
  };

  const updateLicenseCount = () => {
    let totalLicenses = 0;
    for (let i = 0; i < workshops.length; i++) {
      totalLicenses += workshops[i].personInGroup;
    }
    setStorageLicenseCount(totalLicenses);
    if (!props.IsRechedule) {
      var storageLiccen = getLocalStorage("licLocal");
      var pigCount = getLocalStorage("personInGroupCount");
      var avaialbleCount = storageLiccen.available - pigCount;
      var Temp = {
        total: storageLiccen.total,
        available: avaialbleCount,
      };
      setLocalStorage(Temp, "licLocal");
      var templicenseCalculator = {
        total: storageLiccen.total,
        available: avaialbleCount,
      };
      setAppState({
        ...appState,
        licenseCalculator: templicenseCalculator,
      });
    }
  };

  useEffect(() => {
    const workshopsStr = localStorage.getItem("workshops");
    if (workshopsStr !== null && workshopsStr !== "") {
      const storageWorkshops = JSON.parse(workshopsStr);
      setWorkshops(storageWorkshops);
    }

    LoadInitials();
  }, []);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      height: "300px",
      width: "15%",
      transform: "translate(-50%, -50%)",
      borderRadius: "36px",
      border: 0,
      backgroundColor: "transparent",
    },
  };

  return (
    <div>
      <div className="datePickerandtime-row">
        <div className="datePicker-column">
          <div className="datepicker-block">
            <DatePicker
              selected={selectedDate}
              startDate={startDate}
              selectsDisabledDaysInRange={false}
              inline
              minDate={startDate}
              onChange={(date) => onDateChange(date)}
            />
          </div>
          <div className="mission-details-block">
            <div className="mission-info">
              <h2> {missionDetails}</h2>
              <p>
                {" "}
                {Noof}: <span> {people}</span>
              </p>
            </div>
            <div className="mission-input">
              <input
                placeholder="Team Name"
                disabled={props.IsRechedule}
                className={showTeamNameError ? "mission-input-error" : ""}
                value={teamName}
                onChange={(e) => updateTeamName(e.target.value)}
              ></input>
              {showTeamNameError && <FieldMandatoryView />}
              <p> {text1} </p>
            </div>
          </div>
        </div>
        <div className="timeslot-column">
          <div className="timeslot-header">
            <div className="timeslot-left">
              <h1> {timeslotHeader}</h1>
              <p> {timeslotinfo}</p>
            </div>
            <div className="timeslot-right">
              {props.changelanguage ? (
                <div
                  className="changelanguage-block"
                  onClick={props.openModalLanguage}
                >
                  {props.languageCode === "en" && <EnglishIconSvg />}
                  {props.languageCode === "de" && <GermanIconSvg />}
                  {props.languageCode === "it" && <ItalianIconSvg />}
                  {props.languageCode === "fr" && <FrenchIconSvg />}
                  <p>{changeLanguage}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="timeslot-list-block">
            <div className="timeslot-list-header">
              {timezone}: {timeZone}
              {props.dateUnavailable ? (
                <a onClick={props.openModalUnavailable} href="#">
                  {datetimeunav}
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="timeslot-list">
              {(timeSlots || []).map((timeSlot, index) => {
                if (index > 23) {
                  return (
                    <div
                      style={{ display: "none" }}
                      key={index}
                      onClick={() => selectTimeSlot(index)}
                      className={
                        "timeslot " +
                        (timeSlot.isBooked === true ? "booked " : " ") +
                        (timeSlot.isFirstClick === true ? "clicked " : " ") +
                        (timeSlot.isStartOfWorkshop === true
                          ? "makeBooking "
                          : " ")
                      }
                    >
                      {timeSlot.isStartOfWorkshop === true
                        ? Isconfirm
                        : timeSlot.time}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      onClick={() => selectTimeSlot(index)}
                      className={
                        "timeslot " +
                        (timeSlot.isBooked === true ? "booked " : " ") +
                        (timeSlot.isFirstClick === true ? "clicked " : " ") +
                        (timeSlot.isStartOfWorkshop === true
                          ? "makeBooking "
                          : " ")
                      }
                    >
                      {timeSlot.isStartOfWorkshop === true
                        ? Isconfirm
                        : timeSlot.time}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={appState.loader}
        ariaHideApp={true}
        style={customStyles}
        contentLabel="Loader"
      >
        <div
          className="rotating"
          style={{ position: "absolute", left: 50, top: 120 }}
        >
          <img
            src={require("../../assets/loader.svg").default}
            alt="loader"
            height={80}
            width={80}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PickDateTime;
