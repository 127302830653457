import React, { useState, useContext } from "react";
import "./LanguageModal.css";
import {} from "react-bootstrap";
import {
  EnglishIconSvg,
  FrenchIconSvg,
  GermanIconSvg,
  ItalianIconSvg,
} from "../languagedsvg/langsvg.js";
import { LanguageContext } from "../../context/language.context";
import { AppContext } from "../../context/app.context";

const LanguageModal = ({ openModalPickDateTime, self_mod }) => {
  const [language] = useContext(LanguageContext);
  const usedLanguage = language.translations[language.selectedLanguage];
  const { modallanguage } = usedLanguage.Dashboard;
  const { languageHeader, en, de, it, fr, modaltext, mod_italian_unavailable } =
    modallanguage;

  return (
    <div>
      <div className="language-modal">
        <div className="modal-inner-wrapper ">
          <div className="la-modal-header">
            <h2>{languageHeader}</h2>
          </div>
          <div className="language-list">
            <div
              onClick={() => {
                openModalPickDateTime("en");
              }}
              className="language-box"
            >
              <EnglishIconSvg />
              <label>{en}</label>
            </div>
            <div
              onClick={() => {
                openModalPickDateTime("de");
              }}
              className="language-box"
            >
              <GermanIconSvg />
              <label>{de} </label>
            </div>
            <div
              onClick={() => {
                openModalPickDateTime("it");
              }}
              className={`language-box ${!self_mod ? "disabled" : ""}`}
            >
              <ItalianIconSvg />
              <label>{it} </label>
            </div>
            <div
              onClick={() => {
                openModalPickDateTime("fr");
              }}
              className="language-box"
            >
              <FrenchIconSvg />
              <label> {fr} </label>
            </div>
          </div>
          <div className="la-modal-content">{modaltext}</div>
          {!self_mod && (
            <div className="la-modal-content" style={{ marginTop: "30px" }}>
              {mod_italian_unavailable}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LanguageModal;
