import { normalizeUnits } from "moment";
import timeSlot from "../models/timeSlot.js";
import { format } from "date-fns";
import React from "react";

var CryptoJS = require("crypto-js");

// var keysStorage = []
// keysStorage.push("rescheduleBookingLocale");
// keysStorage.push("bookingListLocal");
// keysStorage.push("personInGroupCount");
// keysStorage.push("clientLocal");
// keysStorage.push("contactdetailsLocal");
// keysStorage.push("workshopsLocal");
// keysStorage.push("toggleOrderId");
// keysStorage.push("prospectsLocal");
// keysStorage.push("totalLicenses");
// keysStorage.push("timeslotLocal");
// keysStorage.push("authDetailsLocal");
// keysStorage.push("orderCalculatorLocale");
// keysStorage.push("licLocal");
// keysStorage.push("userDataLocal");
// keysStorage.push("undefined");
// keysStorage.push("workshops");
// keysStorage.push("orderDataLocal");

// var valuesStorage =[]
// valuesStorage.push("MT0XYZ001");
// valuesStorage.push("MTKPM0002");
// valuesStorage.push("MT0000003");
// valuesStorage.push("MT00OPI04");
// valuesStorage.push("MT00HPK05");
// valuesStorage.push("MTJPL0006");
// valuesStorage.push("MT000PMP7");
// valuesStorage.push("MTRTO0008");
// valuesStorage.push("MTJHG0009");
// valuesStorage.push("MT00UJK10");
// valuesStorage.push("MTYTP0011");
// valuesStorage.push("MT0TUK012");
// valuesStorage.push("MTOLP0013");
// valuesStorage.push("MT00HPL14");
// valuesStorage.push("MTAKP0015");
// valuesStorage.push("MTNOA0016");
// valuesStorage.push("MT0JFY017");

// var mapStorage = new Map();

// for(var i = 0; i < keysStorage.length; i++){
//     mapStorage.set(keysStorage[i], valuesStorage[i]);
// }

export const clearSession = () => {
  clearLocalStorage("rescheduleBookingLocale");
  clearLocalStorage("bookingListLocal");
  clearLocalStorage("personInGroupCount");
  clearLocalStorage("clientLocal");
  clearLocalStorage("contactdetailsLocal");
  clearLocalStorage("workshopsLocal");
  clearLocalStorage("toggleOrderId");
  clearLocalStorage("prospectsLocal");
  clearLocalStorage("totalLicenses");
  clearLocalStorage("timeslotLocal");
  clearLocalStorage("authDetailsLocal");
  clearLocalStorage("orderCalculatorLocale");
  clearLocalStorage("licLocal");
  clearLocalStorage("userDataLocal");
  clearLocalStorage("undefined");
  clearLocalStorage("workshops");
  clearLocalStorage("orderDataLocal");
  clearLocalStorage("authDetailsLocalstaff");
};

export const convertToDate = (datetimestring) => {
  ///this function will used when for api format
  var dateinstring = convertDate(datetimestring, "-");
  var date = new Date(dateinstring);
  return fnGetDate(date);
};

export const convertDate = (datetimestring, format = null) => {
  var newDateFormat = "";

  if (
    datetimestring === undefined ||
    datetimestring === null ||
    datetimestring === "" ||
    datetimestring === []
  ) {
    return newDateFormat;
  }

  if (format === undefined || format === null || format === "") {
    format = "";
  }
  var rawDateTime = datetimestring;
  rawDateTime = rawDateTime.substr(0, 10);
  var dateformats = rawDateTime.split("-");

  if (format === "dd/MM/yyyy")
    newDateFormat =
      dateformats[2] + "/" + dateformats[1] + "/" + dateformats[0];
  else if (format === "yyyy/MM/dd")
    newDateFormat =
      dateformats[0] + "/" + dateformats[1] + "/" + dateformats[2];
  else if (format === "yyyy-MM-dd")
    newDateFormat =
      dateformats[0] + "-" + dateformats[1] + "-" + dateformats[2];
  else
    newDateFormat =
      dateformats[0] + "-" + dateformats[1] + "-" + dateformats[2];

  return newDateFormat;
};
export const fnAddDays = (date, dayscount) => {
  var olddate = new Date(date);
  var newDate = new Date(olddate);
  newDate.setDate(olddate.getDate() + dayscount);
  return fnGetDate(newDate);
};

export const fnGetDate = (obj = null) => {
  const today = obj === null ? new Date() : obj;
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return yyyy + "-" + mm + "-" + dd;
};
const AmArray = [
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
];

export const convertTime = (time24) => {
  var ts = time24;
  // var H = +ts.substr(0, 2);
  // var h = (H % 12) || 12;
  // h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
  // var ampm = H < 12 ? " AM" : " PM";
  // ts = h + ts.substr(2, 3) + ampm;

  // var timeWithoutAmPm= time24.substr(0, 5);
  // var checkInclude=AmArray.includes(timeWithoutAmPm);
  // if(checkInclude)
  // {
  //     timeWithoutAmPm=timeWithoutAmPm + " " + "AM"
  // }
  // else{
  //     timeWithoutAmPm=timeWithoutAmPm + " " + "PM"
  // }
  // return ts;
  return time24;
};

export const addHours = (time24) => {
  // Split the input time string into hours and minutes
  let [hours, minutes] = time24.split(":").map(Number);

  // Convert hours and minutes to minutes only
  let totalMinutes = hours * 60 + minutes;

  // Add 1.5 hours (90 minutes)
  totalMinutes += 90;

  // Calculate the new hours and minutes
  let newHours = Math.floor(totalMinutes / 60) % 24;
  let newMinutes = totalMinutes % 60;

  // Format the output time in 24-hour format
  const formattedTime = `${String(newHours).padStart(2, "0")}:${String(
    newMinutes
  ).padStart(2, "0")}`;

  return formattedTime;
};

export const checkWithingTime = (fromdatetime, todatetime) => {
  var rawDateTime = fromdatetime;
  rawDateTime = rawDateTime.substr(0, 9);
  var dateformats = rawDateTime.split("-");
  var newDateFormat =
    dateformats[2] + "/" + dateformats[1] + "/" + dateformats[0];
  return newDateFormat;
};

export const setLocalStorage = (object, name) => {
  // var keyStorage=mapStorage.get(name);
  // var cipherObject = CryptoJS.AES.encrypt(JSON.stringify(object), 'msion123').toString();

  // const localStorageTemp = localStorage.getItem(keyStorage) === null;
  // if(localStorageTemp)
  // {
  //     localStorage.setItem(keyStorage, cipherObject);
  // }
  // else{
  //     localStorage.removeItem(keyStorage);
  //     localStorage.setItem(keyStorage, cipherObject);
  // }

  const localStorageTemp = localStorage.getItem(name) === null;
  if (localStorageTemp) {
    localStorage.setItem(name, JSON.stringify(object));
  } else {
    localStorage.removeItem(name);
    localStorage.setItem(name, JSON.stringify(object));
  }
};

export const clearLocalStorage = (name) => {
  // var keyStorage=mapStorage.get(name);

  // const localStorageTemp = localStorage.getItem(keyStorage) !== 'undefined';
  // if(localStorageTemp)
  // {
  //      localStorage.removeItem(keyStorage);
  // }

  const localStorageTemp = localStorage.getItem(name) !== "undefined";
  if (localStorageTemp) {
    localStorage.removeItem(name);
  }
};

export const getLocalStorage = (name) => {
  // var keyStorage=mapStorage.get(name);
  //  // Encrypt
  // var storage=undefined;
  // const localStorageTemp = localStorage.getItem(keyStorage) !== 'undefined';

  // if(localStorageTemp)
  // {
  //     var data=localStorage.getItem(keyStorage);
  //     var bytes = CryptoJS.AES.decrypt(data, 'msion123');

  //     if(bytes !== 'undefined')
  //     {
  //         storage=JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //     }
  // }

  var storage = undefined;
  const localStorageTemp = localStorage.getItem(name) !== "undefined";
  if (localStorageTemp) {
    var data = localStorage.getItem(name);
    if (data !== "undefined") {
      storage = JSON.parse(data);
    }
  }
  return storage;
};

export const getBookingCount = (bookingList) => {
  if (bookingList === null) {
    return 0;
  } else {
    return bookingList.length;
  }
};

export const fnPurchasedLic = (bookingList) => {
  if (bookingList === null || bookingList === undefined) {
    return 0;
  } else {
    return bookingList.reduce(function (prev, current) {
      return (prev = parseInt(prev) + parseInt(current.personInGroup));
    }, 0);
  }
};

export const fnTotalLic = (userData) => {
  if (userData === null || userData === undefined) {
    return 0;
  } else {
    return parseInt(
      (userData || []).totalLicenses === ""
        ? 0
        : parseInt((userData || []).totalLicenses)
    );
  }
};

export const caseSensative = (data, caseType) => {
  if (data === null || data === undefined || data === [] || data === "") {
    return "";
  } else {
    if (caseType === "upper") {
      return data.toUpperCase();
    } else if (caseType === "lower") {
      return data.toLowerCase();
    }
  }
};

export const getFormatedDate = (date) => {
  return (
    ("0" + date.getDate()).slice(-2) +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    date.getFullYear()
  );
};

export const getApiFormateDate = (date) => {
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  );
};

export const getTimeSlots = () => {
  return [
    new timeSlot("07:30"),
    new timeSlot("08:00"),
    new timeSlot("08:30"),
    new timeSlot("09:00"),
    new timeSlot("09:30"),
    new timeSlot("10:00"),
    new timeSlot("10:30"),
    new timeSlot("11:00"),
    new timeSlot("11:30"),
    new timeSlot("12:00"),
    new timeSlot("12:30"),
    new timeSlot("13:00"),
    new timeSlot("13:30"),
    new timeSlot("14:00"),
    new timeSlot("14:30"),
    new timeSlot("15:00"),
    new timeSlot("15:30"),
    new timeSlot("16:00"),
    new timeSlot("16:30"),
    new timeSlot("17:00"),
    new timeSlot("17:30"),
    new timeSlot("18:00"),
    new timeSlot("18:30"),
    new timeSlot("19:00"),
    new timeSlot("19:30"),
    new timeSlot("20:00"),
    new timeSlot("20:30"),
    new timeSlot("21:00"),
    new timeSlot("21:30"),
    new timeSlot("22:00"),
  ];
};

export const getblockpasttime = () => {
  return [
    new timeSlot("7"),
    new timeSlot("7.3"),
    new timeSlot("8"),
    new timeSlot("8.3"),
    new timeSlot("9"),
    new timeSlot("9.3"),
    new timeSlot("10"),
    new timeSlot("10.3"),
    new timeSlot("11"),
    new timeSlot("11.3"),
    new timeSlot("12"),
    new timeSlot("12.3"),
    new timeSlot("13"),
    new timeSlot("13.3"),
    new timeSlot("14"),
    new timeSlot("14.3"),
    new timeSlot("15"),
    new timeSlot("15.3"),
    new timeSlot("16"),
    new timeSlot("16.3"),
    new timeSlot("17"),
    new timeSlot("17.3"),
    new timeSlot("18"),
    new timeSlot("18.3"),
    new timeSlot("19"),
  ];
};

export const getLanguageCode = (language) => {
  switch (language) {
    case "English":
      return "en";
    case "German":
      return "de";
    case "Italian":
      return "it";
    case "French":
      return "fr";
    default:
      return "en";
  }
};

export const getLanguageByCode = (languageCode) => {
  switch (languageCode) {
    case "en":
      return "english";
    case "de":
      return "german";
    case "it":
      return "italian";
    case "fr":
      return "french";
    default:
      return "en";
  }
};

export const getCurrencyFormated = (value) => {
  const test = 0;
  return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const currencyFormatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 0,
});
export const formatCurrencyIntoEUR = (value) => {
  return currencyFormatter.format(value).replace("€", "");
};

// export const unitPrice =130;
export const GetPrice = () => {
  let uprice = 130;
  if (getLocalStorage("userDataLocal") != null) {
    uprice = getLocalStorage("userDataLocal").licencePrice;
  }
  return uprice;
};

export const GetPriceFacilitator = () => {
  let uprice = 190;
  if (getLocalStorage("userDataLocal") != null) {
    uprice = getLocalStorage("userDataLocal").facilitatorsPrice;
  }
  return uprice;
};

export const defaultTimer = { minutes: 10, seconds: 0 };

export const dateLessThanToday = (datetimestring) => {
  var status = false;
  try {
    var workshopdate = convertDate(datetimestring, "");

    var date = new Date();
    date.setDate(date.getDate() + 1);
    var currentdate = format(date, "yyyy-MM-dd");
    var today = convertDate(currentdate, "");

    if (workshopdate <= today) {
      status = true;
    }

    //return ((new Date(dateString1) <= new Date(dateString)));  //false
  } catch (ex) {
    status = false;
  }
  return status;
};

export const datemMoreThanToday = (datetimestring) => {
  var dateinstring = convertDate(datetimestring, "");
  var date = new Date(dateinstring);
  var today = new Date();
  return new Date(today) > new Date(date);
};

export const fndisableButtonStyle = () => {
  return { color: "#F19202", pointer: "none", opacity: "30%" };
};

export const FieldMandatoryView = (error) => {
  // return (<span className="error">{error !== "" ? error : " This field is mandatory."}</span>)
  return <span className="error">This field is mandatory.</span>;
};

export const convertTo12Hour = (oldFormatTime) => {
  console.log("oldFormatTime: " + oldFormatTime);
  var oldFormatTimeArray = oldFormatTime.split(":");

  var HH = parseInt(oldFormatTimeArray[0]);
  var min = oldFormatTimeArray[1];

  var AMPM = HH >= 12 ? "PM" : "AM";
  var hours;
  if (HH == 0) {
    hours = HH + 12;
  } else if (HH > 12) {
    hours = HH - 12;
  } else {
    hours = HH;
  }
  // var newFormatTime = hours + ":" + min + " " + AMPM;
  var newFormatTime = "0" + hours + ":" + min;
  return newFormatTime;
};

export const dateDurationInDays = (startDate, endDate) => {
  const newStartDate = new Date(startDate);
  const newEndDate = new Date(endDate);
  const one_day = 1000 * 60 * 60 * 24;
  let result;
  result = Math.ceil((newEndDate.getTime() - newStartDate.getTime()) / one_day);

  if (result < 0) {
    return 0;
  }
  return result;
};

export const getfullcountrylist = () => {
  return [
    { name: "Afghanistan", code: "AF" },
    { name: "Åland Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "AndorrA", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Cote D'Ivoire", code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea, Democratic People'S Republic of", code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao People'S Democratic Republic", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia and Montenegro", code: "CS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ];
};
