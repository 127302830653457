import React, { useState, useEffect, useContext } from "react";
import "./AvailableLicenses.css";
import shoppingcart from "../../images/shoppingcart.svg";
import close from "../../images/CloseIcon.svg";
import DynamicCal from "../../components/Dashboard/DynamicCalculator";
import Modal from "react-modal";
import OrderSummary from "../../components/Dashboard/OrderSummary";
import PaymentDetails from "../../components/Dashboard/PaymentDetails";
import Thankyou from "../../components/Dashboard/Thankyou";
import { AppContext } from "../../context/app.context";
import { setLocalStorage } from "../../utils/common";
import defailtJson from "../../utils/data/defaultValues.json";
import { LanguageContext } from "../../context/language.context";
import { baseApiUrl } from "../../utils/base";
import { getLocalStorage } from "../../utils/common";

const apiUrlBooking = baseApiUrl + "client/booking/";

const AvailableLicenses = () => {
  const [appState, setAppState] = useContext(AppContext);
  const [modalIsOpendcalculator, setIsOpendcalculator] = React.useState(false);
  const [modalIsOpendodersummry, setIsOpendodersummry] = React.useState(false);
  const [modalIsOpendpayment, setIsOpenpayment] = React.useState(false);
  const [modalIsOpendthankyou, setIsOpenthankyou] = React.useState(false);

  const [language] = useContext(LanguageContext);
  const usedLanguage = language.translations[language.selectedLanguage];

  const sellang = language.selectedLanguage;
  if (sellang === "") {
    sellang = "en";
  }
  const {
    Dash_AVB_H1,
    Dash_AVB_1,
    Dash_AVB_1_1,
    Dash_AVB_2,
    Dash_AVB_3,
    Dash_AVB_4,
    Dash_AVB_5,
    Dash_AVB_6,
  } = usedLanguage.Dashboard;

  function openModalthankyou() {
    setIsOpenthankyou(true);
    setIsOpenpayment(false);
  }

  const openModalodersummry = () => {
    setIsOpendodersummry(true);
    setIsOpendcalculator(false);
    setIsOpenpayment(false);
  };

  function openModalpayment() {
    setIsOpenpayment(true);
    setIsOpendodersummry(false);
    setIsOpendcalculator(false);
  }

  const setOrderLocalDefault = () => {
    setAppState({
      ...appState,
      liceneceCount: 8,
    });

    setLocalStorage(defailtJson.orderCalculation, "orderCalculatorLocale");
    setLocalStorage(0, "toggleOrderId");
  };

  const openModaldcalculator = (modelId) => {
    switch (modelId) {
      case "purchaseButton":
        setOrderLocalDefault();
        break;
      case "OrderSummary":
        break;
      case "PaymentDetails":
        break;
      default:
        break;
    }
    setIsOpendcalculator(true);
    setIsOpenpayment(false);
    setIsOpendodersummry(false);
  };

  function afterOpenModal() {}

  const closeModal = (modelId) => {
    switch (modelId) {
      case "DynamicCal":
        setOrderLocalDefault();
        setIsOpendcalculator(false);
        break;
      case "OrderSummary":
        setOrderLocalDefault();
        setIsOpendodersummry(false);
        break;
      case "PaymentDetails":
        setOrderLocalDefault();
        setIsOpenpayment(false);
        break;
      case "Thankyou":
        setOrderLocalDefault();
        setIsOpenthankyou(false);
        break;
      default:
        break;
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      padding: "0px",
      maxWidth: "1124px",
      transform: "translate(-50%, -50%)",
      borderRadius: "36px",
      border: "none",
    },
  };

  const getAvaiallbeLic = () => {
    return appState.licenseCalculator != null
      ? appState.licenseCalculator.available
      : 0;
  };
  const getTotalLic = () => {
    return appState.licenseCalculator != null
      ? appState.licenseCalculator.total
      : 0;
  };

  const getAvaialableFacilitatorLicenses = () => {
    return appState.licenseCalculator != null
      ? appState.licenseCalculator.availableFacilitators
      : 0;
  };

  const getTotalFacilitatorLicenses = () => {
    return appState.licenseCalculator != null
      ? appState.licenseCalculator.totalFacilitators
      : 0;
  };

  return (
    <div>
      <section className="available-licenses-card">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>{Dash_AVB_H1}</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="purchlisbackcard">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="available-licenses-number">
                  <span className="avaisnum">
                    {getAvaiallbeLic()}/
                    <span className="avaisnum2">{getTotalLic()}</span>
                  </span>
                  <span className="avalis">{Dash_AVB_1}</span>
                </div>

                <div className="available-licenses-number">
                  <span className="avaisnum">
                    {getAvaialableFacilitatorLicenses()}/
                    <span className="avaisnum2">
                      {getTotalFacilitatorLicenses()}
                    </span>
                  </span>
                  <span className="avalis">{Dash_AVB_1_1}</span>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="available-action">
                  <button
                    className="btmpurls"
                    onClick={() => {
                      openModaldcalculator("purchaseButton");
                    }}
                  >
                    <img src={shoppingcart} alt="" />
                    {Dash_AVB_2}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container" style={{ marginTop: "12px" }}>
          <div className="row">
            <div className="col-lg-12">
              <p className="longtextnew1">{Dash_AVB_3}</p>
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={
          modalIsOpendcalculator || appState.openPurchaseLicenseModalForce
        }
        onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          closeModal("DynamicCal");
          setAppState({ ...appState, openPurchaseLicenseModalForce: false });
        }}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <section
          id="sec1"
          className="modaldcalculator dynamicCal bg-modal-licenses"
        >
          <div className="close-modal">
            <img
              src={close}
              alt=""
              onClick={() => {
                closeModal("DynamicCal");
                setAppState({
                  ...appState,
                  openPurchaseLicenseModalForce: false,
                });
              }}
            />
          </div>
          <DynamicCal></DynamicCal>
          <OrderSummary openModalpayment={openModalpayment}></OrderSummary>
        </section>
      </Modal>
      <Modal
        isOpen={modalIsOpendodersummry}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          closeModal("OrderSummary");
        }}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        {" "}
        <section className="modaldcalculator bg-modal-licenses">
          <div className="close-modal">
            <img
              src={close}
              alt=""
              onClick={() => {
                closeModal("OrderSummary");
              }}
            />
          </div>
          <OrderSummary openModalpayment={openModalpayment}></OrderSummary>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="dc-action">
                  <a
                    id="calqut"
                    onClick={() => {
                      openModaldcalculator("OrderSummary");
                    }}
                    className="calfooter"
                  >
                    {Dash_AVB_4}
                  </a>
                  <a
                    id="odsum"
                    className="calfooter active"
                    style={{ marginLeft: "15px" }}
                  >
                    {Dash_AVB_5}
                  </a>
                  <a
                    id="pay"
                    className="calfooter"
                    style={{ marginLeft: "15px" }}
                  >
                    {Dash_AVB_6}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal>
      <Modal
        isOpen={modalIsOpendpayment}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          closeModal("PaymentDetails");
        }}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        {" "}
        <section className="modaldcalculator bg-modal-licenses">
          <div className="close-modal">
            <img
              src={close}
              alt=""
              onClick={() => {
                closeModal("PaymentDetails");
              }}
            />
          </div>
          <PaymentDetails
            openModalthankyou={openModalthankyou}
          ></PaymentDetails>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="dc-action">
                  <a
                    id="calqut"
                    onClick={() => {
                      openModaldcalculator("PaymentDetails");
                    }}
                    className="calfooter"
                  >
                    {Dash_AVB_4}
                  </a>
                  <a
                    id="odsum"
                    onClick={openModalodersummry}
                    className="calfooter"
                    style={{ marginLeft: "15px" }}
                  >
                    {Dash_AVB_5}
                  </a>
                  <a
                    id="pay"
                    className="calfooter active"
                    style={{ marginLeft: "15px" }}
                  >
                    {Dash_AVB_6}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal>
      <Modal
        isOpen={modalIsOpendthankyou}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          closeModal("Thankyou");
        }}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        {" "}
        <section className="modaldcalculator bg-modal-licenses thsnkyou-modal">
          <div className="close-modal">
            <img
              src={close}
              alt=""
              onClick={() => {
                closeModal("Thankyou");
              }}
            />
          </div>
          <Thankyou></Thankyou>
        </section>
      </Modal>
    </div>
  );
};

export default AvailableLicenses;
